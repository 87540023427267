@import "../../../public/styles/variables";
.astroCardArea {
  width: 100%;
  border: 2px solid $eight-color;
  border-radius: 10px;
  text-align: center;
  padding: 15px 11px;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 0 15px 5px #d9d6d6;
  &__ratting {
    position: absolute;
    top: 10px;
    right: 14px;
    font-size: 16px;
    color: #4c4949;
  }
  &__astroImage {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    margin: -68px auto 30px auto;
    background-color: #fff;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  &__astroDetail {
    text-align: center;
    &__name {
      margin: 15px 0;
      font-size: 25px;
      color: $eight-color;
    }
    &__exprience {
      color: #4c4949;
      font-size: 15px;
    }
    &__details {
      margin: 5px 0;
      color: #4c4949;
      font-size: 15px;
    }
  }
  &__seemore {
    display: flex;
    align-items: center;
    column-gap: 5px;
    justify-content: center;
    padding: 2px;
    width: 60%;
    margin: 10px auto;
    border: 1px solid $eight-color;
    border-radius: 2px;
    color: $eight-color;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background-color: $eight-color;
      color: #ffffff;
    }
  }
  &__button {
    width: 80%;
    text-align: center;
    margin: 30px auto 0 auto;
  }
}
