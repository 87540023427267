@import "../../../../public/styles/breakpoints";
.testimonialArea {
  padding: 20px;
  border: 1px solid gray;
  border-radius: 20px;
  background-color: #fff;
  line-height: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  @include if-device(desktop) {
    padding: 20px;
  }
  @include if-device(phone) {
    padding: 10px;
    line-height: 24px;
  }
  & > h2 {
    color: rgb(6, 77, 77);
  }
}
