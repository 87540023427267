@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.customizeSlot {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 40px 50px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    left: 40%;
    background-color: #aeabab;
  }

  @include if-device(tablet) {
    flex-wrap: wrap;
    padding: 50px;
    &::before {
      display: none;
    }
  }
  @include if-device(largephone) {
    flex-wrap: wrap;
    padding: 30px;
    &::before {
      display: none;
    }
  }
  @include if-device(phone) {
    flex-wrap: wrap;
    padding: 20px;
    &::before {
      display: none;
    }
  }

  &__left {
    padding-right: 30px;
    width: 40%;
    position: sticky;
    top: 50px;
    height: 100%;
    @include if-device(tablet) {
      width: 100%;
      text-align: center;
      padding-bottom: 30px;
      border-right: none;
      border-bottom: 1px solid #aeabab;
      margin-bottom: 50px;
      padding-right: 0;
      position: static;
    }
    @include if-device(largephone) {
      width: 100%;
      text-align: center;
      padding-bottom: 30px;
      border-right: none;
      border-bottom: 1px solid #aeabab;
      margin-bottom: 50px;
      padding-right: 0;
      position: static;
    }
    @include if-device(phone) {
      width: 100%;
      text-align: center;
      padding-bottom: 30px;
      border-right: none;
      border-bottom: 1px solid #aeabab;
      margin-bottom: 50px;
      padding-right: 0;
      position: static;
    }

    &__top {
      margin-bottom: 20px;
      & > h3 {
        font-size: 32px;
        margin-bottom: 20px;
      }

      & > p {
        font-size: 14px;
      }
    }

    &__mid {
      display: flex;
      align-items: flex-start;
      column-gap: 30px;

      @include if-device(tablet) {
        justify-content: center;
      }

      @include if-device(largephone) {
        justify-content: center;
      }

      @include if-device(phone) {
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 20px;
      }

      &__astro {
        height: 120px;
        width: 120px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid $eight-color;

        & > img {
          height: 100%;
          width: 100%;
        }
      }

      &__astrodetails {
        & > h3 {
          font-size: 30px;
          font-weight: 500;
          margin-bottom: 10px;
        }

        &__ex {
          font-size: 18px;
          margin-bottom: 10px;
        }

        & > h2 {
          color: #2c6959;
          margin-bottom: 10px;
        }

        &__desig {
          font-size: 14px;
        }
      }
    }

    &__bot {
      position: relative;
      width: 100%;
      height: 220px;

      &__image {
        width: 220px;
        position: absolute;
        left: -30px;
        @include if-device(tablet) {
          left: 50%;
          transform: translateX(-50%);
        }

        @include if-device(largephone) {
          left: 50%;
          transform: translateX(-50%);
        }

        @include if-device(phone) {
          left: 50%;
          transform: translateX(-50%);
        }
        & > img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  &__right {
    padding-left: 30px;
    width: 60%;
    @include if-device(tablet) {
      width: 100%;
    }
    @include if-device(largephone) {
      width: 100%;
      padding-left: 0;
    }
    @include if-device(phone) {
      width: 100%;
      padding-left: 0;
    }
    &__days {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      & > h3 {
        font-size: 30px;
        font-weight: 500;
        @include if-device(largephone) {
          font-size: 25px;
        }

        @include if-device(phone) {
          font-size: 20px;
        }
      }

      & > input {
        height: 25px;
        width: 25px;
        accent-color: red;
      }

      &__icon {
        font-size: 25px;

        @include if-device(largephone) {
          font-size: 20px;
        }

        @include if-device(phone) {
          font-size: 16px;
        }
      }

      & > p {
        font-size: 24px;
        @include if-device(largephone) {
          font-size: 20px;
        }

        @include if-device(phone) {
          font-size: 16px;
        }
      }
    }

    &__weeks {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 20px;
      @include if-device(phone) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
      }
      @include if-device(largephone) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
      }
      &__navi {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: #a4beb7;
      }

      &__day {
        height: 80px;
        width: 60px;
        border-radius: 5px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $eight-color;
        cursor: pointer;
        font-weight: 700;

        @include if-device(largephone) {
          height: 60px;
          width: 50px;
          font-size: 15px;
        }

        @include if-device(phone) {
          height: 60px;
          font-size: 15px;
          width: 50px;
        }
      }
    }

    &__head {
      font-size: 18px;
      margin-bottom: 10px;
    }

    &__newSlot {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 10px;
      flex-direction: column;
      // overflow-x: scroll;
      padding-bottom: 10px;

      &__head {
        font-size: 18px;
        margin-bottom: 10px;
      }
      &__slot {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 20px;
        flex-wrap: wrap;
        row-gap: 20px;
        &__time {
          border: 2px solid #2c6959;
          padding: 10px 5px;
          width: 180px;

          cursor: pointer;

          @include if-device(largephone) {
            width: 170px;
            font-size: 15px;
          }

          @include if-device(phone) {
            width: 170px;
            font-size: 15px;
          }
          & > span {
            padding-left: 10px;
            & > i {
              color: red;
            }
          }
          &__dayrange {
            color: #2c6959;
            font-weight: 700;
          }
        }
      }
    }

    &__timeSlot {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      &__slots {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 20px;
        flex-wrap: wrap;
        row-gap: 20px;
        width: 100%;
      }
      &__time {
        padding: 10px;
        width: 180px;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &__range {
          border: 2px solid #2c6959;
          padding: 10px 5px;
          width: 180px;
          cursor: pointer;
          & > span {
            padding-left: 6px;
            & > i {
              color: red;
            }
          }
        }
        @include if-device(largephone) {
          width: 170px;
          font-size: 15px;
        }

        @include if-device(phone) {
          width: 170px;
          font-size: 15px;
        }

        &__dayrange {
          color: #2c6959;
          font-weight: 700;
        }
      }
      &__add {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: $eight-color;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer;
      }
      &__close {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: $eight-color;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer;
      }
    }

    &__btn {
      display: flex;
      column-gap: 20px;
      @include if-device(phone) {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
}

.active {
  background-color: #fff;
  color: $eight-color;
  border: 1px solid $eight-color;
}

.slotActive {
  background-color: #2c6959;
  color: #fff;
}
.notDelet {
  cursor: no-drop;
}
