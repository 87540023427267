@import "../../../../public/styles/breakpoints";
@import "../../../../public/styles/variables";
.kundliCard {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 6px 3px #dedbdb99;
  border-radius: 5px;
  border: 1.5px solid #777777;
  cursor: pointer;
  transition: 0.3s;
  @include if-device(desktop) {
    padding: 10px;
  }
  &:hover {
    background-color: $eight-color;
    color: #fff;
    border-color: $eight-color;
  }

  &__cardImage {
    width: 120px;
    height: 150px;
    overflow: hidden;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    @include if-device(desktop) {
      width: 100px;
      height: 100px;
      margin-bottom: 20px;
    }
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__cardName {
    font-size: 16px;
    text-align: center;
    @include if-device(desktop) {
      font-size: 16px;
    }
  }
  & > p {
    text-align: center;
    font-size: 17px;
    // color: $eight-color;
  }
}
