@import "../../../../public/styles/breakpoints";
.cardArea {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 12px 3px #9999;
  border-radius: 5px;
  border: 1.5px solid #777777;
  cursor: pointer;

  @include if-device(desktop) {
    padding: 10px;
  }

  &__cardImage {
    width: 100px;
    height: 150px;
    overflow: hidden;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    @include if-device(desktop) {
      width: 100px;
      height: 100px;
      margin-bottom: 20px;
    }
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__cardName {
    font-size: 16px;
    @include if-device(desktop) {
      font-size: 16px;
    }
  }
}
