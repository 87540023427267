@import "../../../../public/styles/variables";
.buttonArea {
  width: 100%;
  background-color: $eight-color;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
