@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.container {
  padding: 0 80px 80px;
  margin-top: -60px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  @include if-device(desktop) {
    padding: 80px 50px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: -150px;
    gap: 20px;
  }

  @include if-device(tablet) {
    padding: 50px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: -100px;
    gap: 20px;
  }
  @include if-device(largephone) {
    padding: 50px;
    grid-template-columns: repeat(1, 1fr);
    margin-top: -100px;
    gap: 20px;
  }

  @include if-device(phone) {
    padding: 50px 20px;
    grid-template-columns: repeat(1, 1fr);
    margin-top: -100px;
  }
}
.description {
  height: 400px;
  overflow-y: scroll;
  font-size: 25px;
  padding: 40px;
  line-height: 40px;
  text-align: center;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $eight-color;
  }
  & > h2 {
    text-align: center;
    margin-bottom: 10px;
    color: $eight-color;
  }
  @include if-device(phone) {
    font-size: 20px;
    padding: 30px;
  }
}
.emptyMsg {
  display: inline-block;
  width: 100%;
  height: 350px;
  overflow: hidden;
  @include if-device(largephone) {
    height: 280px;
  }
  @include if-device(tablet) {
    height: 350px;
  }
  @include if-device(tablet) {
    height: 250px;
  }
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
