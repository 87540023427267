@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";
.consultaintItem {
  width: 45%;
  border: 2px solid #777;
  padding: 20px;
  column-gap: 30px;
  border-radius: 10px;
  position: relative;
  display: flex;
  overflow: hidden;
  height: 420px;
  @include if-device(desktop) {
    width: 100%;
  }
  @include if-device(tablet) {
    width: 100%;
  }
  @include if-device(largephone) {
    width: 100%;
    height: auto;
  }
  @include if-device(phone) {
    flex-wrap: wrap;
    width: 100%;
    height: auto;
  }
  &__all {
    display: flex;
    align-items: center;
    column-gap: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 3px 6px;
    border-radius: 3px;
    color: $eight_color;
    cursor: pointer;
    z-index: 777;

    & > input {
      width: 15px;
      height: 15px;
    }
  }
  &__bigImg {
    width: 200px;
    height: 200px;
    position: absolute;
    bottom: 0;
    right: 0;
    @include if-device(phone) {
      position: relative;
      width: 100% !important;
      height: 200px;
    }
    & > img {
      width: 100%;
      height: 100%;

      @include if-device(phone) {
        position: absolute;
        right: -20px;
        bottom: -20px;
        width: 260px;
        height: 200px;
      }
    }
  }
}
