@import "../../../../public/styles/breakpoints";
.cardArea {
  width: 100%;
  padding: 5px 40px;
  background-color: #fff;
  box-shadow: 0 0 15px 5px #9999;
  border-radius: 5px;
  border: 1.5px solid #777777;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 30px;
  cursor: pointer;
  @include if-device(desktop) {
    padding: 10px;
    column-gap: 10px;
  }
  @include if-device(largephone) {
    padding: 10px;
    column-gap: 10px;
  }
  @include if-device(phone) {
    padding: 20px;
    column-gap: 10px;
    flex-wrap: wrap;
  }
  &__cardImage {
    width: 48%;
    height: 200px;
    overflow: hidden;
    @include if-device(phone) {
      width: 100%;
    }
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__cardDetail {
    flex: 1;
    & > p {
      line-height: 24px;
      font-size: 16px;
      font-weight: 700;
      @include if-device(phone) {
        text-align: center;
      }
    }
  }
}
