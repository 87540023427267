@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";
.profileArea {
  width: 400px;
  background-color: #fff;
  height: 100vh;
  position: fixed;
  z-index: 999999999;
  top: 70px;
  right: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: all 0.3s ease-in-out;
  animation: in 1s forwards;
  overflow: hidden;
  padding-bottom: 80px;

  border: 1px solid $eight-color;
  border-radius: 6px;
  @include if-device(phone) {
    width: 275px;
    top: 50px;
  }
  @include if-device(largephone) {
    width: 350px;
    top: 50px;
  }

  &__close {
    display: inline-block;
    position: absolute;
    top: 15px;
    left: 20px;
    cursor: pointer;
    font-size: 20px;
    color: $white-color;
  }
  &__email {
    margin: 20px 0;
    font-size: 20px;
  }
  &__imgArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    padding: 45px 8px 20px;
    background-image: linear-gradient(45deg, #0d3235, #1eb2a6);
    flex-wrap: wrap;
    row-gap: 15px;
    &__emailrea {
      flex: 1;
      @include if-device(phone) {
        order: 2;
      }
      &__email {
        margin-top: 10px;
        @include if-device(phone) {
          font-size: 15px;
        }
        @include if-device(largephone) {
          font-size: 15px;
        }
      }
    }
    &__profilePic {
      display: block;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      border: 1px solid #3d80a0;
      overflow: hidden;

      @include if-device(phone) {
        width: 80px;
        height: 80px;
        order: 1;
      }
      @include if-device(largephone) {
        width: 80px;
        height: 80px;
      }
      & > img {
        height: 100%;
        width: 100%;
      }
      &__edit {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 999999;
        font-size: 25px;
        cursor: pointer;

        & > i {
          color: #fff;
        }
      }
    }
  }
  & > h2 {
    font-size: 28px;
  }
  &__detail {
    padding: 0 30px;
    @include if-device(phone) {
      padding: 0 10px 0 15px;
    }
    @include if-device(largephone) {
      padding: 0 10px 0 20px;
    }
    &__experience {
      margin: 22px 0;
      font-size: 17px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      & > span {
        color: #0c464f;
        font-size: 18px;
        @include if-device(phone) {
          font-size: 16px;
        }
        @include if-device(largephone) {
          font-size: 16px;
        }
        & > i {
          margin-right: 8px;
        }
      }
      @include if-device(phone) {
        font-size: 15px;
        display: block;
      }
      @include if-device(largephone) {
        font-size: 15px;
      }
    }
    &__speciality {
      font-size: 17px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      & > span {
        color: #0c464f;
        font-size: 18px;
        & > i {
          margin-right: 8px;
        }
      }
    }
    &__buttonArea {
      margin: 50px 0 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px;
      & > button {
        width: 130px;
        padding: 6px 0;
        font-size: 18px;
        border-radius: 5px;
        transition: 0.3s ease;
      }
      &__courseBtn {
        background-color: $eight-color;
        border: 1px solid $eight-color;
        color: #fff;
        cursor: pointer;
        &:hover {
          background-color: #fff;
          color: $eight-color;
        }
      }
      &__logout {
        background-color: #f22222;
        border: 1px solid #f22222;
        color: #fff;
        cursor: pointer;
        &:hover {
          background-color: #fff;
          color: #f22222;
        }
      }
    }
  }

  &__title {
    border-bottom: 2px solid $eight-color;
    padding: 18px 0;
    text-align: center;
    background-color: $eight-color;
    color: $white-color;
  }
  &__etid {
    padding: 20px 30px 80px 30px;
    height: 100%;
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: $eight-color;
    }
    overflow-y: scroll;
    overflow-x: hidden;
    &__profilePic {
      display: block;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      border: 1px solid $eight-color;
      // overflow: hidden;
      margin: auto;
      position: relative;

      & > div {
        height: 100%;
        width: 100%;
        overflow: hidden;
        border-radius: 50%;
      }
    }
    &__back {
      position: absolute;
      top: 16px;
      left: 20px;
      color: $white-color;
      font-size: 23px;
    }
    &__camera {
      position: absolute;
      bottom: 0;
      right: 0;
      color: $eight-color;
      font-size: 23px;
      z-index: 999;
      background-color: #fff;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__input {
      width: 100%;
      margin: 10px 0;
      display: block;
      padding: 8px;
      border: 1px solid $eight-color;
      font-size: 18px;
      outline-color: #13827e;
      border-radius: 4px;
    }
    &__btn {
      margin: 20px 0;
      width: 100%;
      padding: 8px;
      font-size: 18px;
      background-color: $eight-color;
      color: $white-color;
      border: 1px solid $eight-color;
      border-radius: 3px;
      transition: 0.3s;
      cursor: pointer;
      &:hover {
        background-color: #fff;
        color: $eight-color;
      }
    }
    &__selectArea {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      margin: 15px 0;
      &__select {
        width: 100%;
        padding: 8px;
        border: 1px solid $eight-color;
        font-size: 18px;
        outline-color: #217ad2;
        border-radius: 4px;
        margin-top: 5px;
      }
    }
    &__select {
      width: 100%;
      padding: 8px;
      border: 1px solid $eight-color;
      font-size: 18px;
      outline-color: #217ad2;
      border-radius: 4px;
      margin: 8px 0;
    }
  }
}
@keyframes in {
  0% {
    right: -400px;
    @include if-device (phone) {
      right: 300px;
    }
    @include if-device (phone) {
      right: 300px;
    }
  }
  100% {
    right: 0;
  }
}
.ant-picker-dropdown {
  position: relative;
  z-index: 999999999999999999999999999;
}
.datePicker {
  width: 100%;
  padding: 10px 20px;
  font-size: 18px;
  color: #333;
  border: 1px solid #1eb2a6;
  border-radius: 4px;
}
