@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.timeSlot {
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: center;
  border: 1px solid $eight-color;
  border-radius: 5px;
  overflow: hidden;
  @include if-device(phone) {
    flex-wrap: wrap;
  }

  &__child {
    position: relative;
    @include if-device(tablet) {
      flex-wrap: wrap;
    }
    @include if-device(largephone) {
      width: 33%;
    }
    @include if-device(phone) {
      width: 100%;
      margin-bottom: 10px;
    }

    & > p {
      color: #000;
      font-size: 20px;
    }

    & > input {
      width: 100%;
      height: 45px;
      padding: 10px 10px;
      font-size: 20px;
      outline: none;
      border: none;
      color: $eight-color;
      appearance: none;
      position: relative;
      &:focus {
        outline: none;
        border-color: $eight-color;
      }
      &::-webkit-calendar-picker-indicator {
        opacity: 0;
        font-size: 30px;
        z-index: 999;
        cursor: pointer;
      }
    }

    & > img {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 10px;
      top: 16%;
      cursor: pointer;
      z-index: 99;
    }
  }
}
