@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.astroListArea {
  padding: 50px 50px;
  background-color: #fff;
  font-family: Philosopher;

  @include if-device(tablet) {
    padding: 50px;
  }
  @include if-device(largephone) {
    padding: 50px;
  }
  @include if-device(phone) {
    padding: 20px;
  }

  &__tittleArea {
    display: flex;
    align-items: center;
    column-gap: 80px;
    margin-bottom: 90px;
    justify-content: space-between;
    @include if-device(largephone) {
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 20px;
    }
    @include if-device(phone) {
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 20px;
    }
    &__searchArea {
      border: 1px solid $eight-color;
      width: 400px;
    }
  }

  &__loader {
    z-index: 200;
  }
  &__astrologerList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 50px;
    row-gap: 100px;
    @include if-device(desktop) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include if-device(tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include if-device(largephone) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include if-device(phone) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
.emptyMsg {
  width: 100%;
  height: 200px;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
