@import "../../../public//styles/breakpoints";

.heading {
  display: flex;
  align-items: center;
  column-gap: 30px;
  font-size: 30px;
  font-weight: 700;
  @include if-device(desktop) {
    flex-wrap: wrap;
    row-gap: 30px;
  }
  @include if-device(tablet) {
    flex-wrap: wrap;
    row-gap: 15px;
      font-size: 25px;

  }
  @include if-device(largephone) {
    flex-wrap: wrap;
    row-gap: 15px;
      font-size: 25px;

  }
  @include if-device(phone) {
    flex-wrap: wrap;
    row-gap: 15px;
      font-size: 25px;

  }

  &__img {
    width: 70px;
    height: 70px;
    overflow: hidden;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  &__orangeText {
    color: #a73500;
  }
  &__blueText {
    color: #222344;
    text-decoration: underline;
  }
  &__maroonText {
    color: #970505;
  }
}
