@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.graphologyForm {
  width: 100%;
  padding: 50px;
  @include if-device(phone) {
    padding: 30px 20px;
  }

  &__top {
    display: flex;
    align-items: center;
    column-gap: 40px;
    width: 70%;
    @include if-device(desktop) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(tablet) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(largephone) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(phone) {
      flex-wrap: wrap;
      row-gap: 20px;
      width: 100%;
    }
  }
  &__content {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    padding: 60px 40px;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    column-gap: 100px;
    margin-top: 30px;
    @include if-device(desktop) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(tablet) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(largephone) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(phone) {
      flex-wrap: wrap;
      row-gap: 20px;
      width: 100%;
      padding: 30px 8px;
    }

    &__left {
      width: 60%;

      @include if-device(desktop) {
        width: 100%;
        order: 2;
      }
      @include if-device(tablet) {
        width: 100%;
        order: 2;
      }
      @include if-device(largephone) {
        width: 100%;
        order: 2;
      }
      @include if-device(phone) {
        width: 100%;
        order: 2;
      }

      &__tittle {
        margin-bottom: 30px;
      }
      &__formArea {
        width: 100%;

        &__input {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          justify-content: space-between;
          column-gap: 40px;
          align-items: center;

          & > p {
            margin: 30px 0 0 0;
            font-size: 18px;
            color: #1eb2a6;
            text-align: center;
            border: 0.5px solid #1eb2a6;
            padding: 8px;
          }

          @include if-device(phone) {
            grid-template-columns: repeat(1, 1fr);
            display: block;
          }
          &__select {
            margin: 15px 0;
            & > p {
              font-size: 18px;
              color: #68574e;
              margin-bottom: 6px;
            }
          }
        }
      }
      &__buttonArea {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        place-items: center;
        column-gap: 10px;

        @include if-device(phone) {
          grid-template-columns: repeat(1, 1fr);
        }
        @include if-device(largephone) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    &__right {
      width: 40%;
      @include if-device(desktop) {
        width: 100%;
        order: 1;
      }
      @include if-device(tablet) {
        width: 100%;
        order: 1;
      }
      @include if-device(largephone) {
        width: 100%;
        order: 1;
      }
      @include if-device(phone) {
        width: 100%;
        order: 1;
      }

      &__sampleImg {
        display: flex;
        align-items: center;
        column-gap: 80px;
        margin-top: 30px;

        @include if-device(largephone) {
          column-gap: 20px;
        }
        @include if-device(phone) {
          column-gap: 20px;
          flex-wrap: wrap;
        }

        & > h4 {
          font-size: 25px;
        }

        &__img {
          height: 150px;
          width: 200px;

          @include if-device(phone) {
            height: 100px;
            width: 150px;
          }

          & > img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
.paymentOption {
  padding: 15px;
  position: relative;
  & > h1 {
    text-align: center;
    color: $eight-color;
    margin-bottom: 25px;
    @include if-device(phone) {
      font-size: 20px;
      margin-top: 20px;
    }
  }
  &__cros {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 26px;
    width: 26px;
    cursor: pointer;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  &__option {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 20px;
    @include if-device(phone) {
      grid-template-columns: 1fr;
    }
  }
}
.showPayment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: #1eb2a6;
  border: 1px solid;
  padding: 10px;
  border-radius: 5px;
  margin-top: 25px;
  & > p {
    & > span {
      color: #767676;
      text-transform: capitalize;
    }
  }
  & > span {
    & > i {
      cursor: pointer;
    }
  }
}
