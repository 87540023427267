@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.bookingArea {
  padding: 50px 100px;
  background: #f3f5f8;
  @include if-device(desktop) {
    padding: 50px;
  }
  @include if-device(tablet) {
    padding: 50px;
  }
  @include if-device(largephone) {
    padding: 50px;
  }
  @include if-device(phone) {
    padding: 30px 20px;
  }

  &__navBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 40px;

    @include if-device(desktop) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(tablet) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(largephone) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(phone) {
      flex-wrap: wrap;
      row-gap: 20px;
    }

    &__left {
      display: flex;
      align-items: center;

      @include if-device(tablet) {
        flex-wrap: wrap;
        row-gap: 20px;
      }
      @include if-device(largephone) {
        flex-wrap: wrap;
        row-gap: 20px;
      }
      @include if-device(phone) {
        flex-wrap: wrap;
        row-gap: 20px;
      }
    }
  }
  &__bookingCardArea {
    padding: 80px 0px 0 0;

    border-radius: 10px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 30px;
    row-gap: 80px;
    text-align: center;
    flex-wrap: wrap;
    @include if-device(desktop) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include if-device(tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include if-device(largephone) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include if-device(phone) {
      grid-template-columns: repeat(1, 1fr);
    }
    &__card {
      width: 100%;
      // height: 100%;
    }
    &__imageArea {
      height: 300px;
      &__emptyMsg {
        position: absolute;
        left: 50%;
        font-size: 20px;
        transform: translateX(-50%);
        width: 100%;
        height: 250px;
        & > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
.loder {
  position: absolute;
  top: 120px;
  left: 50%;
}
