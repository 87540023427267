@import "../../../public/styles/breakpoints";
.content {
  width: 100%;
  padding: 50px 80px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  column-gap: 30px;
  @include if-device(phone) {
    display: block;
    text-align: center;
    padding: 10px;
  }
  @include if-device(largephone) {
    display: block;
    text-align: center;
  }
  &__icon {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #82abc1;
    border-radius: 10px;
    transition: all 0.3s ease;

    &:hover {
      transform: rotate(-20deg);
    }
    @include if-device(phone) {
      width: auto;
      margin-bottom: 30px;
    }
    @include if-device(largephone) {
      width: auto;
      margin-bottom: 30px;
    }
  }

  &__desc {
    & > p {
      line-height: 30px;
    }
  }
}
