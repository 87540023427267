@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.kundaliArea {
  width: 100%;
  padding: 50px 100px;

  @include if-device(desktop) {
    padding: 50px;
    display: flex;
    flex-direction: column;
  }
  @include if-device(tablet) {
    padding: 50px;
    display: flex;
    flex-direction: column;
  }
  @include if-device(largephone) {
    padding: 50px;
    display: flex;
    flex-direction: column;
  }
  @include if-device(phone) {
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
  }
  &__kundaliType {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 30px;
    @include if-device(desktop) {
      // order: 1;
      margin: 20px 0;
    }
    @include if-device(tablet) {
      // order: 1;
      margin: 20px 0;
      grid-template-columns: 1fr;
    }
    @include if-device(largephone) {
      // order: 1;
      grid-template-columns: 1fr;

      margin: 20px 0;
    }
    @include if-device(phone) {
      // order: 1;
      margin: 20px 0;
      grid-template-columns: 1fr;

      row-gap: 20px;
    }
  }
  &__formArea {
    display: flex;
    column-gap: 80px;
    margin: 40px 0;

    @include if-device(desktop) {
      flex-wrap: wrap;
    }
    @include if-device(tablet) {
      flex-wrap: wrap;
    }
    @include if-device(largephone) {
      flex-wrap: wrap;
    }
    @include if-device(phone) {
      flex-wrap: wrap;
    }

    &__bigContainer {
      @include if-device(desktop) {
        width: 100%;
      }
      @include if-device(tablet) {
        width: 100%;
      }
      @include if-device(largephone) {
        width: 100%;
      }
      @include if-device(phone) {
        width: 100%;
      }
      & > p {
        margin: 10px 0;
        color: #444;
        font-size: 16px;
        font-weight: 500;
      }
      & > span {
        color: black;
        font-size: 20px;
        font-weight: 500;
      }
      &__consultaintItem {
        width: 100%;
        border: 2px solid #c4c4c4;
        padding: 20px;
        display: flex;
        column-gap: 30px;
        border-radius: 10px;
        position: relative;
        margin-bottom: 30px;

        @include if-device(phone) {
          flex-wrap: wrap;
          width: 100%;
        }

        & > div {
          & > ul {
            & > li {
              list-style: square;
              margin: 15px 15px;
              color: #444;
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
        &__bigImg {
          width: 200px;
          height: 200px;
          position: absolute;
          bottom: 0;
          right: 0;
          @include if-device(phone) {
            position: relative;
            width: 100% !important;
            height: 200px;
          }
          & > img {
            width: 100%;
            height: 100%;
            @include if-device(phone) {
              position: absolute;
              right: -20px;
              bottom: -20px;
              width: 200px;
              height: 200px;
            }
          }
        }
      }
    }

    &__formField {
      flex: 1;
      display: flex;
      flex-direction: column;

      & > h2 {
        color: #a73500;
        font-weight: 700;
        font-size: 25px;
        @include if-device(desktop) {
          order: 2;
        }
        @include if-device(tablet) {
          order: 2;
        }
        @include if-device(largephone) {
          order: 2;
        }
        @include if-device(phone) {
          order: 2;
        }
      }
      &__form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        @include if-device(desktop) {
          order: 3;
        }
        @include if-device(tablet) {
          order: 3;
        }
        @include if-device(largephone) {
          order: 3;
          column-gap: 20px;
        }
        @include if-device(phone) {
          order: 3;
          grid-template-columns: 1fr;
        }
        &__timeSlot {
          font-size: 18px;
          color: #1eb2a6;
          text-align: center;
          border: 0.5px solid #1eb2a6;
          padding: 8px;
          margin-top: 25px;
        }
        &__select {
          margin-top: 15px;
          & > p {
            font-size: 18px;
            color: #444;
            margin-bottom: 6px;
          }
        }
      }
      &__buttonArea {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        column-gap: 20px;
        row-gap: 30px;
        margin: 30px 0;
        place-items: center;

        @include if-device(desktop) {
          order: 4;
          margin-bottom: 0;
        }
        @include if-device(tablet) {
          order: 4;
          margin-bottom: 0;
          grid-template-columns: 1fr 1fr;
        }
        @include if-device(largephone) {
          order: 4;
          margin-bottom: 0;
          column-gap: 20px;
          grid-template-columns: 1fr;
        }
        @include if-device(phone) {
          order: 4;
          margin-bottom: 0;
          column-gap: 20px;
          grid-template-columns: 1fr;
        }

        & > div {
          width: 100%;
        }
      }
    }
  }
}
.datePicker {
  width: 100%;
  padding: 10px 20px;
  font-size: 18px;
  color: #333;
  border: 1px solid #1eb2a6;
  border-radius: 4px;
}
.loader {
  height: 400px;
}

.paymentOption {
  padding: 15px;
  position: relative;
  & > h1 {
    text-align: center;
    color: $eight-color;
    margin-bottom: 25px;
    @include if-device(phone) {
      font-size: 20px;
      margin-top: 20px;
    }
  }
  &__cros {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 26px;
    width: 26px;
    cursor: pointer;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  &__option {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 20px;
    @include if-device(phone) {
      grid-template-columns: 1fr;
    }
  }
}
.showPayment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: #1eb2a6;
  border: 1px solid;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  & > p {
    & > span {
      color: #767676;
      text-transform: capitalize;
    }
  }
  & > span {
    & > i {
      cursor: pointer;
    }
  }
}
