.select {
  width: 100%;
  height: 45px;
  background-color: #fff;
  border: 1px solid #34baaf;
  padding: 10px;
  font-size: 20px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  z-index: 1;
  border-radius: 4px;

  &__content {
    &__img {
      height: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      display: flex;
      align-items: center;

      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__options {
    width: 100%;
    background-color: #fff;
    border: 1px solid #a29d9b;
    position: absolute;
    top: 44px;
    left: 0;
    border-radius: 5px;
    overflow: hidden;
    animation: open 0.2s forwards;

    &__option {
      height: 30px;
      padding: 5px 10px;
      cursor: pointer;
      border-bottom: 1px solid #a29d9b;
      font-size: 16px;

      &:hover {
        background-color: rgb(70, 70, 239);
        color: #fff !important;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

@keyframes open {
  from {
    transform: translateY(-10px);
    transform-origin: top;
  }
  to {
    transform: translateY(0);
    transform-origin: top;
  }
}
