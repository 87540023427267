@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";
.feddbackAre{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    max-width: 1180px;
    margin: auto;
    &__feedbackform{
        width: 100%;
        &>h2{
            font-size: 2rem;
            color: $eight-color;
            margin-bottom: 20px;
            @include if-device(phone){
                font-size: 1.5rem;
            }
        }
        &>label{
            display: block;
            margin: 10px 0;
        }
        &>textarea{
            width: 100%;
            min-height: 150px;
            padding: 10px;
            outline: none;
            border-color: $eight-color;
            border-radius: 5px;
            font-size: 17px;
        }
    }
}
.rating{
   &>label{
    font-size: 2rem;
    color: #faaf00;
   }
}