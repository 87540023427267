@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";
.blogDetail {
  max-width: 1140px;
  margin: auto;
  padding: 20px;
  &__title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 30px;
    color: $eight_color;
    text-decoration: underline;
    padding-bottom: 5px;
  }
  &__bannerArea {
    width: 100%;
    &__sliderArea {
      &__image {
        width: 100%;
        height: 400px;
        @include if-device(phone) {
          height: 300px;
        }
        & > img {
          width: 100%;
          height: 100%;
        }
      }
      &__detalArea {
        margin: 20px 0;
        &__smallTitle {
          cursor: pointer;
          line-height: 1.5em;
          color: $eight_color;
          font-size: 15px;
          letter-spacing: 1px;
          font-weight: 700;
          text-transform: uppercase;
        }
        &__name {
          font-size: 18px;
          margin: 5px 0;
          font-weight: 700;
        }
        &__tittle {
          font-size: 25px;
          color: rgb(7, 72, 71);
          text-transform: uppercase;
          margin: 10px 0 10px 0;
          @include if-device(phone) {
            font-size: 20px;
          }
        }
        &__paragraf {
          font-size: 17px;
          line-height: 28px;
          color: #8d8c8c;
          font-weight: 400;
        }
        &__notification {
          display: flex;
          column-gap: 15px;
          color: #0e473c;
          font-size: 17px;
          margin: 5px 0 10px 0;
          & > span {
            & > i {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}
.home_slider {
  width: 100% !important;
  margin: 0% !important;
}
