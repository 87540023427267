@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.courseCard {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  // @include if-device(desktop) {
  //   height: 350px;
  // }
  // @include if-device(tablet) {
  //   height: 350px;
  // }
  // @include if-device(largephone) {
  //   height: 350px;
  // }
  // @include if-device(phone) {
  //   height: auto;
  //   width: 350px;
  // }

  &__courseImg {
    width: 100%;
    height: 220px;
    position: relative;
    & > span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(45deg, rgb(90 229 194 / 22%), rgb(194 3 3 / 21%));
      font-size: 40px;
      color: #1eb2a6;
      & > i {
        background-color: #fff;
        border-radius: 50%;
      }
    }
    @include if-device(phone) {
      height: 200px;
    }
    & > img {
      width: 100%;
      height: 100%;
      // object-fit: contain;
    }
  }
  &__courseDet {
    padding: 10px;
  }
  &__courseDet {
    &__title {
      // margin: 20px 20px 10px;
      font-size: 20px;
      line-height: 30px;
      color: #155651;
      @include if-device(largephone) {
        font-size: 16px;
      }
      @include if-device(phone) {
        font-size: 16px;
      }
    }
    &__cName {
      font-size: 16px;
      margin: 5px 0;

      & > span {
        font-size: 17px;
        color: $eight-color;
        margin-right: 10px;
      }
    }
    &__tag {
      font-size: 16px;
      margin: 5px 0;
      color: $eight-color;
      margin-bottom: 10px;
      & > span {
        font-size: 17px;
        color: $eight-color;
        margin-right: 10px;
      }
    }
  }

  &__det {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 30px 20px;
    // @include if-device(desktop) {
    //   position: absolute;
    //   bottom: 0;
    //   width: 100%;
    // }
    // @include if-device(tablet) {
    //   position: absolute;
    //   bottom: 0;
    //   width: 100%;
    // }
    // @include if-device(largephone) {
    //   position: absolute;
    //   bottom: 0;
    //   width: 100%;
    // }
    // @include if-device(phone) {
    //   width: 100%;
    //   padding: 10px;
    // }

    // &__btn {
    //   width: 120px;
    // }
  }
}
