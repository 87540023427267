@import "../../../public//styles/breakpoints";
@import "../../../public//styles/variables";

.modalArea {
  width: 100%;
  background: transparent;
  display: grid;

  padding: 50px 100px;

  @include if-device(desktop) {
    padding: 50px;
  }
  @include if-device(tablet) {
    padding: 50px;
  }
  @include if-device(largephone) {
    padding: 50px;
  }
  @include if-device(phone) {
    padding: 20px;
    display: block;
  }

  &__modal {
    width: 100%;
    background-color: #fff;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    padding: 60px 30px;
    border-radius: 10px;
    margin: 40px 0;
    @include if-device(phone) {
      padding: 0%;
    }
    &__formArea {
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
      // align-items: center;
      flex-wrap: wrap;

      @include if-device(desktop) {
        flex-wrap: wrap;
        row-gap: 30px;
      }
      @include if-device(tablet) {
        flex-wrap: wrap;
        row-gap: 30px;
      }
      @include if-device(largephone) {
        flex-wrap: wrap;
        row-gap: 30px;
      }
      @include if-device(phone) {
        flex-wrap: wrap;
        row-gap: 30px;
      }

      &__detail {
        width: 62%;
        @include if-device(desktop) {
          width: 100%;
          order: 2;
        }
        @include if-device(tablet) {
          width: 100%;
          order: 2;
        }
        @include if-device(largephone) {
          width: 100%;
          order: 2;
        }
        @include if-device(phone) {
          width: 100%;
          padding: 0 5px;
          order: 2;
        }

        & > span {
          color: #3c3c3c;
          font-size: 20px;
          margin-bottom: 10px;
          display: inline-block;
        }
        &__formField {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 20px;
          align-items: center;
          &__timeSlot {
            font-size: 18px;
            color: #1eb2a6;
            text-align: center;
            border: 0.5px solid #1eb2a6;
            padding: 8px;
            margin-top: 10px;
            @include if-device(phone) {
              margin: 15px;
            }
            @include if-device(largephone) {
              margin-bottom: 15px;
            }
          }
          &__select {
            margin: 15px 0;
            & > p {
              font-size: 18px;
              color: #444;
              margin-bottom: 6px;
            }
          }
          @include if-device(tablet) {
            grid-template-columns: 1fr 1fr;
          }
          @include if-device(largephone) {
            grid-template-columns: 1fr;
          }
          @include if-device(phone) {
            grid-template-columns: 1fr;
            display: block;
          }
        }

        &__buttonArea {
          display: flex;
          align-items: center;
          column-gap: 30px;

          @include if-device(desktop) {
            flex-wrap: wrap;
            row-gap: 30px;
          }
          @include if-device(tablet) {
            flex-wrap: wrap;
            row-gap: 10px;
            justify-content: center;
          }
          @include if-device(largephone) {
            flex-wrap: wrap;
            row-gap: 10px;
            justify-content: center;
          }
          @include if-device(phone) {
            flex-wrap: wrap;
            row-gap: 10px;
            justify-content: center;
          }

          &__man {
            height: 200px;
            width: 80px;
            @include if-device(largephone) {
              width: 100%;
            }
            @include if-device(phone) {
              width: 100%;
            }
            & > img {
              width: 100%;
              height: 100%;
              @include if-device(phone) {
                object-fit: contain;
              }
              @include if-device(largephone) {
                object-fit: contain;
              }
            }
          }

          &__dragDrop {
            width: 250px;
            @include if-device(phone) {
              width: 100%;
            }
          }
        }
      }
      &__plane {
        // width: 32%;
        flex: 1;
        padding-top: 90px;
        @include if-device(desktop) {
          width: 100%;
          padding: 0%;
        }
        @include if-device(tablet) {
          width: 100%;
          padding: 0%;
        }
        @include if-device(largephone) {
          width: 100%;
          padding: 0%;
        }
        @include if-device(phone) {
          width: 100%;
          padding: 0;
        }

        & > div {
          margin-bottom: 40px;
        }
        & > h4 {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 10px;

          & > span {
            display: inline-block;
            height: 40px;
            width: 35px;
            overflow: hidden;
            & > img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
.datePicker {
  width: 100%;
  padding: 10px 20px;
  font-size: 18px;
  color: #333;
  border: 1px solid #1eb2a6;
  border-radius: 4px;
}
.emptyMsg {
  display: inline-block;
  width: 90%;
  height: 350px;
  overflow: hidden;
  @include if-device(largephone) {
    height: 280px;
  }
  @include if-device(tablet) {
    height: 350px;
  }
  @include if-device(tablet) {
    height: 250px;
  }
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.paymentOption {
  padding: 15px;
  position: relative;
  & > h1 {
    text-align: center;
    color: $eight-color;
    margin-bottom: 25px;
    @include if-device(phone) {
      font-size: 20px;
      margin-top: 20px;
    }
  }
  &__cros {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 26px;
    width: 26px;
    cursor: pointer;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  &__option {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 20px;
    @include if-device(phone) {
      grid-template-columns: 1fr;
    }
  }
}
.showPayment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: #1eb2a6;
  border: 1px solid;
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
  & > p {
    & > span {
      color: #767676;
      text-transform: capitalize;
    }
  }
  & > span {
    & > i {
      cursor: pointer;
    }
  }
}
