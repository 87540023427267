@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";
.courseDetails {
  &__bannerArea {
    background-image: linear-gradient(45deg, rgba(41, 57, 57, 0.946), rgba(234, 253, 254, 0)), url(../../assets/learnBg.webp);
    color: #fff;
    line-height: 28px;

    &__container {
      padding: 80px 150px;
      @include if-device(desktop) {
        padding: 80px 50px;
      }
      @include if-device(tablet) {
        padding: 50px;
      }
      @include if-device(largephone) {
        padding: 50px;
      }
      @include if-device(phone) {
        padding: 50px 20px;
      }
      & > p {
        margin-bottom: 25px;
        font-size: 18px;
        color: aqua;
        @include if-device(phone) {
          font-size: 18px;
          margin-bottom: 15px;
        }

        & > span {
          font-size: 18px;
          cursor: pointer;
          &:hover {
            color: #0e9a8e;
          }
          @include if-device(phone) {
            font-size: 16px;
          }
          & > i {
            font-size: 17px;
            margin: 0 4px;
          }
        }
        & > i {
          font-size: 17px;
          margin: 0 4px;
        }
      }
      & > h1 {
        font-size: 45px;
        text-transform: uppercase;
        @include if-device(phone) {
          font-size: 25px;
        }
      }
      & > h4 {
        font-size: 22px;
        margin: 12px 0;
        @include if-device(phone) {
          font-size: 15px;
          margin: 5px 0;
        }
      }
      &__rattingArea {
        font-size: 18px;
        display: flex;
        column-gap: 10px;
      }
    }
  }
  &__detailsArea {
    padding: 20px 150px;
    @include if-device(phone) {
      padding: 20px 10px;
    }
    @include if-device(largephone) {
      padding: 30px;
    }
    @include if-device(tablet) {
      padding: 40px;
    }
    & > h1 {
      margin-bottom: 20px;
      color: #053834;
      text-align: center;
    }
    &__title {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 30px;
      @include if-device(largephone) {
        font-size: 30px;
      }

      @include if-device(phone) {
        font-size: 30px;
      }
      & > h1 {
        color: $eight-color;
        font-size: 40px;
        margin-bottom: 10px;
        font-family: $font_2;
        z-index: 1;
        position: relative;

        @include if-device(largephone) {
          font-size: 30px;
        }
        @include if-device(phone) {
          font-size: 30px;
          text-align: center;
          margin-top: 30px;
        }
      }
      &__arrowTitle {
        height: 1px;
        width: 20%;
        border: 0.2px solid $eight-color;
        border-radius: 1px;
        position: relative;
        @include if-device(largephone) {
          width: 30%;
        }

        @include if-device(phone) {
          width: 30%;
        }
        &::before {
          content: "";
          position: absolute;
          width: 50px;
          height: 6px;
          background-color: $eight-color;
          top: -3px;
          left: 0%;
          transform: translateX(-50%);
          border-radius: 2px;
          @include if-device(largephone) {
            width: 30px;
          }

          @include if-device(phone) {
            width: 30px;
          }
        }
      }
    }
    &__videoArea {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 25px;
      margin-bottom: 20px;

      @include if-device(phone) {
        grid-template-columns: 1fr;
        height: auto;
      }
      @include if-device(largephone) {
        grid-template-columns: 1fr;
        height: auto;
      }
      @include if-device(tablet) {
        grid-template-columns: 1fr;
        height: auto;
      }
      @include if-device(desktop) {
        grid-template-columns: 1fr;
        height: auto;
      }
      &__videoCard {
        background-color: #1e1d1c;
        padding-bottom: 10px;
        @include if-device(phone) {
          border-bottom: 1px solid #0d9b8f;
          padding-bottom: 10px;
        }
        @include if-device(largephone) {
          border-bottom: 3px solid #0d9b8f;
          padding-bottom: 10px;
        }
        @include if-device(tablet) {
          border-bottom: 3px solid #0d9b8f;
          padding-bottom: 10px;
        }
        @include if-device(desktop) {
          border-bottom: 3px solid #0d9b8f;
          padding-bottom: 10px;
        }
        &__video {
          height: 340px;
          border: 1px solid #0e9a8e;
          background-color: #000;
          @include if-device(phone) {
            height: 230px;
          }
          @include if-device(largephone) {
            height: 300px;
          }
          @include if-device(tablet) {
            height: 380px;
          }
          & > video {
            object-fit: cover;
          }
        }
        &__detailArea {
          flex: 1;
          text-align: left;
          margin-top: 10px;
          padding-left: 10px;
          & > h2 {
            color: #1eb2a6;
            margin-bottom: 5px;
            font-size: 35px;
            @include if-device(phone) {
              font-size: 25px;
            }
          }
          & > p {
            margin-bottom: 5px;
            color: #fff;
            @include if-device(phone) {
              font-size: 15px;
            }
          }
          &__notification {
            display: flex;
            column-gap: 30px;
            color: #8d8c8c;
            font-size: 16px;
            margin: 10px 0;
          }
        }
      }
      &__priceArea {
        padding: 5px 5px 10px 5px;
        text-align: center;
        max-height: 470px;
        overflow-y: hidden;
        position: relative;

        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background-color: #fff;
        }

        &::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: $eight-color;
          border-radius: 5px;
        }
        &__overLay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-image: linear-gradient(45deg, rgb(6 72 56 / 70%), rgb(218 173 173 / 60%));
          background-color: #00000099;
          display: flex;
          align-items: center;
          justify-content: center;
          // border-radius: 10px;

          &__detail {
            color: #fff;
            text-align: center;
            padding: 0 10px;
            & > img {
              display: inline-block;
              width: 100px;
              height: 100px;
              margin-bottom: 5px;
            }
            & > h1 {
              font-size: 25px;
              @include if-device(phone) {
                font-size: 20px;
              }
            }
            &__checkbox {
              margin-top: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 15px;
              & > input {
                width: 25px;
                height: 25px;
                @include if-device(phone) {
                  width: 20px;
                  height: 20px;
                }
              }
              & > label {
                color: #ffc107;
                font-size: 20px;
                width: auto;
                @include if-device(phone) {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
    &__descriptionArea {
      padding-top: 20px;
      border-top: 2px solid #1eb2a6;
      &__cardArea {
        box-shadow: 0 0 10px 3px #d2cbcb;
        padding: 20px 10px;
        margin-bottom: 25px;
        & > h1 {
          color: #053834;
          margin-bottom: 10px;
          font-size: 28px;
          font-family: emoji;

          @include if-device(phone) {
            font-size: 20px;
            margin-bottom: 5px;
          }
        }
        & > p {
          font-size: 18px;
          line-height: 28px;
          color: #0d7f76;
          @include if-device(phone) {
            font-size: 16px;
          }
          & > ul {
            padding-left: 28px;
          }
          & > ol {
            padding-left: 28px;
          }
        }
        & > div {
          & > ul {
            list-style: decimal;
            padding-left: 35px;
            & > li {
              font-size: 20px;
              color: #0d7f76;
              margin: 8px 0;
            }
          }
          & > ol {
            list-style: decimal;
            padding-left: 35px;
            & > li {
              font-size: 20px;
              color: #0d7f76;
              margin: 8px 0;
            }
          }
        }
      }
    }
  }
}
.css-ryrseu-MuiRating-root {
  font-size: 20px !important;
}
.emptyMsg {
  display: inline-block;
  width: 90%;
  height: 350px;
  overflow: hidden;
  @include if-device(largephone) {
    height: 280px;
  }
  @include if-device(tablet) {
    height: 350px;
  }
  @include if-device(tablet) {
    height: 250px;
  }
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.vLoding {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.paymentOption {
  padding: 15px;
  position: relative;
  & > h1 {
    text-align: center;
    color: $eight-color;
    margin-bottom: 25px;
    @include if-device(phone) {
      font-size: 20px;
      margin-top: 20px;
    }
  }
  &__cros {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 26px;
    width: 26px;
    cursor: pointer;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  &__option {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 20px;
    @include if-device(phone) {
      grid-template-columns: 1fr;
    }
  }
}
.showPayment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: #1eb2a6;
  border: 1px solid;
  padding: 10px;
  border-radius: 5px;
  margin-top: 25px;
  & > p {
    & > span {
      color: #fff;
      text-transform: capitalize;
    }
  }
  & > span {
    & > i {
      cursor: pointer;
    }
  }
}
