@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";
.container {
  height: 450px;
  width: 60%;
  margin: 50px auto;
  background-color: $white-color;
  box-shadow: 0 0 15px 2px $seventh-color;
  position: relative;
  border-radius: 3px;
  @include if-device(phone) {
    width: 98%;
  }
  @include if-device(largephone) {
    width: 90%;
  }
  @include if-device(tablet) {
    width: 90%;
  }
  @include if-device(desktop) {
    width: 75%;
  }
  &__popupArea {
    width: 100%;
    height: 100%;
    clip-path: polygon(100% 0, 100% 100%, 0% 0%);
    background-image: linear-gradient(45deg, #17abc4, #1eb2a6);
  }
  &__messageArea {
    border: 1px solid $seventh-color;
    background-color: $white-color;
    border-radius: 5px;
    padding: 20px;
    height: 80%;
    width: 75%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 15px 2px $seventh-color;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include if-device(phone) {
      width: 95%;
      padding: 0%;
      height: 90%;
    }
    @include if-device(largephone) {
      width: 95%;
    }
    @include if-device(tablet) {
      width: 75%;
    }
    @include if-device(desktop) {
      width: 75%;
    }
    &__img {
      width: 100px;
      height: 100px;
      & > img {
        height: 100%;
        width: 100%;
      }
    }
    & > h2 {
      font-size: 25px;
      text-transform: uppercase;
      // color: $eight-color;
      margin: 10px 0;
    }
    & > p {
      font-size: 18px;
      // color: $tenth-color;
      color: green;
      margin: 5px;
    }
  }
}
