@import "../../../public/styles/variables";

.icon {
  height: 50px;
  width: 50px;
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: $white-color;
  border: 1px solid $eight-color;
  z-index: 99;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: $shawdow3;
  overflow: hidden;

  & > img {
    height: 100%;
    width: 100%;
  }
}
