@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";
.astrodetail {
  text-align: center;
  font-size: 40px;
  background-color: #fff;
  padding: 10px;
  color: $eight-color;
  @include if-device(phone) {
    font-size: 25px;
  }
  @include if-device(largephone) {
    font-size: 30px;
  }
  @include if-device(tablet) {
    font-size: 30px;
  }
}
.profileArea {
  background-color: #fff;
  height: 90%;
  padding: 10px 0;
  color: $eight-color;
  border: 1px solid $eight-color;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @include if-device(phone) {
    display: block;
  }
  @include if-device(largephone) {
    display: block;
  }
  @include if-device(tablet) {
    display: block;
  }
  &::before {
    content: "";
    height: 80%;
    width: 1px;
    background-color: $eight-color;
    position: absolute;
    left: 45%;
    @include if-device(phone) {
      display: none;
    }
    @include if-device(largephone) {
      display: none;
    }
    @include if-device(tablet) {
      display: none;
    }
  }
  &__left {
    text-align: center;
    &__close {
      display: inline-block;
      position: absolute;
      top: 10px;
      left: 10px;
      cursor: pointer;
      height: 25px;
      width: 25px;
      padding: 5px;
      &:hover {
        background-color: $white-color;
      }
      & > img {
        height: 100%;
        width: 100%;
      }
    }
    &__email {
      margin: 20px 0;
      font-size: 22px;
      @include if-device(phone) {
        margin: 5px 0 !important;
        font-size: 18px !important;
      }
      @include if-device(largephone) {
        margin: 7px 0 !important;
        font-size: 18px !important;
      }
      @include if-device(tablet) {
        margin: 7px 0 !important;
        font-size: 22px !important;
      }
    }
    &__profilePic {
      display: block;
      width: 220px;
      height: 220px;
      border-radius: 50%;
      border: 1px solid $eight-color;
      overflow: hidden;
      margin: 0px auto 20px auto;
      @include if-device(phone) {
        width: 150px;
        height: 150px;
      }
      @include if-device(largephone) {
        width: 160px;
        height: 160px;
      }
      @include if-device(tablet) {
        width: 180px;
        height: 180px;
      }
      & > img {
        width: 100%;
        height: 100%;
      }
    }
    & > h2 {
      font-size: 38px;
      margin: 30px 0;
      @include if-device(phone) {
        font-size: 25px;
        margin: 5px;
      }
      @include if-device(largephone) {
        font-size: 30px;
        margin: 6px;
      }
      @include if-device(tablet) {
        font-size: 32px;
        margin: 8px;
      }
    }
  }
  &__right {
    @include if-device(phone) {
      text-align: center;
    }
    @include if-device(largephone) {
      text-align: center;
    }
    @include if-device(tablet) {
      text-align: center;
    }
    &__experience {
      margin: 40px 0;
      font-size: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 5px;
      & > span {
        color: #000;
      }
      @include if-device(phone) {
        font-size: 25px;
        margin: 20px 0;
      }
      @include if-device(largephone) {
        font-size: 30px;
        margin: 22px 0;
      }
      @include if-device(tablet) {
        font-size: 30px;
        margin: 22px 0;
      }

      & > a {
        text-decoration: none;

        & > button {
          width: 100px;
          height: 40px;
          background-color: $eight-color;
          border: 1px solid $eight-color;
          border-radius: 10px;
          font-size: 16px;
          color: white;
          cursor: pointer;
        }
      }
    }
  }
}
