@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";
.cardArea {
  position: relative;
  text-align: center;
  border: 1px solid $eight-color;
  padding: 20px;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  background-color: #fff;
  height: 100%;
  flex-wrap: wrap;
  display: flex;
  // align-items: end;
  &__userNameImg{
    &>img{
      width: 20px;
      margin-left: 10px;
      cursor: pointer;
    transition: 0.3s;
    animation: preloader 0.6s ease-in-out alternate 0.4s infinite;
    &:hover{
      animation: none;
  
    }
    }
  }
  &__topics {
    width: 100%;
    margin-bottom: 20px;
    & > p {
      display: flex;
      align-items: center;
    }
    &__ul {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style-type: none;
      flex-wrap: wrap;

      &__li {
        font-size: 14px;
        text-align: center;
      }

      &__read {
        color: $eight-color;
        cursor: pointer;
        font-size: 18px;

        &__icon {
          color: $eight-color;
        }
      }
    }
  }

  &__cardImg {
    display: inline-block;
    width: 120px;
    height: 120px;
    overflow: hidden;
    margin: -60px auto 0 auto;
    border-radius: 50%;
    border: 1px solid $eight-color;

    & > img {
      width: 100%;
      height: 100%;
    }
  }
  & > h3 {
    color: $eight-color;
    margin: 10px 0;
    width: 100%;
  }
  &__serviceType {
    font-size: 13px;
    font-weight: 700;
        width: 100%;
  }
  &__detail {
    font-size: 14px;
    line-height: 22px;
    margin: 10px 0;
    color: #444;
    text-align: center;
    width: 100%;
  }
  &__cardDateTime {
    width: 100%;
    margin: auto;
    padding: 10px;
    border: 1px solid $eight-color;
    background-color: #e1f5fe;
    border-radius: 3px;
    margin-bottom: 10px;

    & > p {
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 2px;
    }
    & > span {
      font-size: 12px;
      font-weight: 700;
      // color: ;
    }
  }
  &__contact {
    display: inline-block;
    width: 40px;
    height: 40px;
    overflow: hidden;
    margin-bottom: -40px;
    background-color: #fff;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
}
.selectArea {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  & > select {
    flex: 1;
    padding: 8px 0;
    font-size: 19px;
    background-color: #0d3e3a;
    color: #fff;
    outline: none;
    border-radius: 5px;
    width: 100%;
    height: 45px;
    @include if-device(phone) {
      font-size: 17px;
    }
    @include if-device(largephone) {
      font-size: 17px;
    }
  }
  & > span {
    display: inline-block;
    padding: 8px 5px;
    border: 1px solid #0d3e3a;
    font-size: 22px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    width: 100%;
    height: 58px;
    flex: 1;
    &:hover {
      background-color: #0d3e3a;
      color: #fff;
    }
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.dragArea {
  width: 80%;
  margin: auto;
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.userDetailArea {
  padding: 20px 50px;
  text-align: center;
  @include if-device(phone) {
    padding: 20px;
  }
  & > h2 {
    font-size: 35px;
    color: $eight-color;
    margin-bottom: 15px;
  }
  & > ul {
    list-style: none;
    text-align: left;

    & > li {
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      font-size: 20px;
      margin: 8px 0;
      border-bottom: 1px solid #07bcad;
      @include if-device(phone) {
        display: block;
      }
      & > span {
        display: inline-block;
        flex: 1;
        @include if-device(phone) {
          display: block;
        }
      }
    }
  }
  &__identitiText {
    color: #07bcad;
    font-weight: 600;
  }
}
.userFileArea {
  padding: 20px 50px;
  text-align: center;
  @include if-device(phone) {
    padding: 20px;
  }
  & > h2 {
    font-size: 35px;
    color: $eight-color;
    margin-bottom: 15px;
  }
  & > ul {
    display: flex;
    list-style: none;
    border: 1px solid #07bcad;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 20px;
    @include if-device(phone) {
      display: block;
    }
    & > li {
      padding: 8px;
      border-right: 1px solid #07bcad;
      cursor: pointer;
      transition: 0.3s;
      width: 100%;
      @include if-device(phone) {
        border-bottom: 1px solid #07bcad;
      }
      &:hover {
        background-color: #07bcad;
        color: #fff;
      }
    }
  }
  &__userImgArea {
    border: 1px solid #07bcad;
    padding: 5px;
    border-radius: 2px;
    height: 300px;
    overflow: hidden;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.activeTab {
  background-color: #07bcad;
  color: #fff;
}
.statusArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-top: 10px;
  width: 100%;
}
@keyframes preloader {
  100% {
    transform: scale(1.4);
  }
}