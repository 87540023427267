@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.faceReading {
  padding: 50px 70px;
  background-color: #fff;

  @include if-device(desktop) {
    padding: 50px;
  }
  @include if-device(tablet) {
    padding: 50px;
  }
  @include if-device(largephone) {
    padding: 50px;
  }
  @include if-device(phone) {
    padding: 30px 20px;
  }
  &__tittleArea {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    column-gap: 50px;
    @include if-device(tablet) {
      flex-wrap: wrap;
      row-gap: 20px;
      width: 100%;
    }
    @include if-device(largephone) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(phone) {
      flex-wrap: wrap;
      row-gap: 20px;
    }

    &__tabField {
      width: 25%;
      border-radius: 30px;
      overflow: hidden;
      border: 2px solid #c4c4c4;
    }
  }

  &__content {
    display: flex;
    column-gap: 20px;

    @include if-device(desktop) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(tablet) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(largephone) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(phone) {
      flex-wrap: wrap;
      row-gap: 20px;
    }

    &__left {
      width: 60%;
      @include if-device(desktop) {
        width: 100%;
        order: 2;
      }
      @include if-device(tablet) {
        width: 100%;
        order: 2;
      }
      @include if-device(largephone) {
        width: 100%;
        order: 2;
      }
      @include if-device(phone) {
        width: 100%;
        order: 2;
      }
      &__top {
        display: flex;
        justify-content: space-between;
        column-gap: 40px;

        @include if-device(tablet) {
          column-gap: 10px;
          justify-content: flex-start;
          flex-wrap: wrap;
        }
        @include if-device(desktop) {
          column-gap: 40px;
          flex-wrap: wrap;

          justify-content: flex-start;
        }

        @include if-device(largephone) {
          flex-wrap: wrap;
        }
        @include if-device(phone) {
          flex-wrap: wrap;
        }

        &__topic {
          border: 1px solid #aeabab;
          padding: 20px;
          border-radius: 4px;
          width: 300px;
          // height: 330px;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            width: 5px;
            border-radius: 5px;
            @include if-device(phone) {
              width: 5px;
            }
            @include if-device(largephone) {
              width: 5px;
            }
          }

          &::-webkit-scrollbar-track {
            background-color: #fff;
          }

          &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: $eight-color;
          }
          @include if-device(desktop) {
            width: 100%;
            margin-bottom: 30px;
          }
          @include if-device(tablet) {
            width: 100%;
            margin-bottom: 20px;
          }
          @include if-device(largephone) {
            width: 100%;
            margin-bottom: 20px;
          }
          @include if-device(phone) {
            width: 100%;
            margin-bottom: 20px;
          }

          & > h2 {
            color: $eight-color;
            font-size: 30px;
            margin-bottom: 30px;
          }

          &__row {
            display: flex;
            align-items: center;
            column-gap: 10px;
            margin-bottom: 15px;

            & > input {
              height: 20px;
              width: 20px;
            }

            & > p {
              font-size: 20px;
            }
          }
          &__all {
              display: flex;
              align-items: center;
              column-gap: 20px;
              border-radius: 3px;
              color: $eight_color;
              cursor: pointer;
              z-index: 777;

              & > input {
                width: 15px;
                height: 15px;
              }
          }
        }
        &__form {
          width: 100%;
          &__fields {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 10px;
            row-gap: 10px;
            margin-bottom: 30px;
            @include if-device(tablet) {
              column-gap: 20px;
            }
            @include if-device(largephone) {
              column-gap: 20px;
            }
            @include if-device(desktop) {
              column-gap: 10px;
            }
            @include if-device(phone) {
              grid-template-columns: repeat(1, 1fr);
              display: block;
            }
            &__select {
              &__slot {
                margin: 30px 0 0 0;
                font-size: 18px;
                color: #1eb2a6;
                text-align: center;
                border: 0.5px solid #1eb2a6;
                padding: 8px;
                @include if-device(phone) {
                  height: 40px;
                }
                @include if-device(largephone) {
                  height: 40px;
                }
                @include if-device(tablet) {
                  height: 40px;
                }
              }
            }
          }

          &__dragDrop {
            display: flex;
            column-gap: 20px;
            @include if-device(tablet) {
              column-gap: 10px;
            }
            @include if-device(largephone) {
              column-gap: 10px;
            }
            @include if-device(phone) {
              column-gap: 10px;
              flex-wrap: wrap;
            }
          }
        }
      }
      &__bottom {
        display: flex;
        align-items: center;
        column-gap: 30px;

        @include if-device(largephone) {
          flex-wrap: wrap;
          justify-content: center;
        }
        @include if-device(phone) {
          flex-wrap: wrap;
          justify-content: center;
        }

        &__face {
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 10px;
          margin-top: 10px;

          &__image {
            height: 115px;

            @include if-device(phone) {
              height: 100%;
            }

            & > img {
              width: 100%;
              height: 100%;
            }
          }
        }

        &__btn {
          display: flex;
          align-items: center;
          column-gap: 20px;
          @include if-device(phone) {
            flex-wrap: wrap;
            justify-content: center;
          }
        }
      }
    }

    &__right {
      width: 40%;
      display: flex;
      flex-direction: column;
      row-gap: 70px;

      @include if-device(desktop) {
        width: 100%;
        order: 1;
        row-gap: 30px;
        margin-bottom: 20px;
      }
      @include if-device(tablet) {
        width: 100%;
        order: 1;
        row-gap: 30px;
        margin-bottom: 20px;
      }
      @include if-device(largephone) {
        width: 100%;
        row-gap: 30px;
        order: 1;
        margin-bottom: 20px;
      }
      @include if-device(phone) {
        width: 100%;
        order: 1;
        row-gap: 20px;
        margin-bottom: 20px;
      }
    }
  }
}
.emptyMsg {
  display: inline-block;
  width: 90%;
  height: 350px;
  overflow: hidden;
  @include if-device(largephone) {
    height: 280px;
  }
  @include if-device(tablet) {
    height: 350px;
  }
  @include if-device(tablet) {
    height: 250px;
  }
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.paymentOption {
  padding: 15px;
  position: relative;
  & > h1 {
    text-align: center;
    color: $eight-color;
    margin-bottom: 25px;
    @include if-device(phone) {
      font-size: 20px;
      margin-top: 20px;
    }
  }
  &__cros {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 26px;
    width: 26px;
    cursor: pointer;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  &__option {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 20px;
    @include if-device(phone) {
      grid-template-columns: 1fr;
    }
  }
}
.showPayment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: #1eb2a6;
  border: 1px solid;
  padding: 10px;
  border-radius: 5px;
  margin-top: 25px;
  & > p {
    & > span {
      color: #767676;
      text-transform: capitalize;
    }
  }
  & > span {
    & > i {
      cursor: pointer;
    }
  }
}
