@import "../../../../public/styles/breakpoints";
.cardArea {
  padding: 10px;
  display: flex;
  margin-bottom: 10px;
  border-radius: 4px;
  column-gap: 20px;
  row-gap: 20px;
  box-shadow: 0 0 8px 1px #d2cbcb;

  &__imgArea {
    width: 30%;
    height: 150px;
    border-radius: 10px;
    border: 1px solid #888;
    overflow: hidden;
    position: relative;
    & > span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(
        45deg,
        rgb(90 229 194 / 22%),
        rgb(194 3 3 / 21%)
      );
      font-size: 40px;
      color: #1eb2a6;
      @include if-device(phone) {
        font-size: 30px;
      }
    }
    @include if-device(phone) {
      height: auto;
    }
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  &__detailArea {
    flex: 1;
    text-align: left;
    & > h2 {
      color: #1eb2a6;
      margin-bottom: 5px;
      font-size: 23px;
      @include if-device(phone) {
        font-size: 15px;
      }
    }
    & > p {
      margin-bottom: 5px;
      @include if-device(phone) {
        font-size: 11px;
      }
    }
    &__notification {
      display: flex;
      column-gap: 20px;
      color: #8d8c8c;
      font-size: 16px;
      margin: 5px 0;
      @include if-device(phone) {
        font-size: 11px;
        margin: 0;
      }
    }
  }
}
