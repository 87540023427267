@import "../../../../public/styles/breakpoints";
@import "../../../../public/styles/variables";
.form {
  width: 50%;
  @include if-device(tablet) {
    width: 100%;
  }
  @include if-device(largephone) {
    width: 100%;
  }
  @include if-device(phone) {
    width: 100%;
  }

  &__left {
    display: flex;
    flex-direction: column;

    & > h2 {
      font-size: 45px;
      color: $eight-color;
      margin-bottom: 25px;
    }

    &__textarea {
      padding: 5px 7px;
      border: 1px solid #fff;
      margin: 5px 0;
      border-radius: 3px;
      font-size: 16px;

      &::placeholder {
        font-size: 16px;
      }

      &:focus {
        outline: none;
        border: 1px solid #fff;
      }
    }

    &__buttonWidth {
      width: 50%;
      margin: 0 auto;
    }
  }
}
