@import "../../../../public/styles/breakpoints";
.Banner2Area {
  display: flex;
  align-items: center;
  // background-image: url("../../../assets/bg.jpg");
  justify-content: space-between;
  width: 100%;
  height: 600px;
  z-index: 10;
  overflow: hidden;
  background-size: cover;
  @include if-device(phone) {
    height: auto;
  }
  @include if-device(tablet) {
    height: auto;
  }
  @include if-device(largephone) {
    height: auto;
  }
  &__circleImg {
    flex: 1;
    height: 100%;
    overflow: hidden;
    & > img {
      position: relative;
      left: -50%;
      animation: rotate 20s linear infinite;
    }
  }
  &__carouselArea {
    width: 100%;
    height: 100%;
    &__banner {
      height: 100%;
      & > img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
