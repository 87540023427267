@import "../../../../public/styles/breakpoints";
@import "../../../../public/styles/variables";

.courseTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  @include if-device(phone) {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 20px;
  }
  &__latest {
    font-size: 30px;
    color: $eight-color;
    text-transform: capitalize;

    @include if-device(phone) {
      text-align: center;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  &__view {
    font-size: 20px;
    color: $eight-color;
    text-transform: capitalize;
    font-weight: 700;
    cursor: pointer;
  }
}
