@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  &__img {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include if-device(tablet) {
      width: 80%;
      height: 70%;
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__name {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    bottom: 20px;
    color: $black-color;
    @include if-device(phone) {
      bottom: 10px;
    }
    @include if-device(largephone) {
      bottom: 10px;
    }
    & > h3 {
      text-transform: capitalize;
      font-size: 22px;
      @include if-device(largephone) {
        font-size: 18px;
      }
      @include if-device(phone) {
        font-size: 16px;
      }
    }

    & > p {
      text-transform: uppercase;
      margin-top: 5px;
      @include if-device(largephone) {
        font-size: 16px;
      }
      @include if-device(phone) {
        font-size: 14px;
      }
    }
  }
}
