@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";
.tittle {
  width: 100%;
  font-size: 40px;
  font-weight: 600;
  color: $eight-color;
  text-transform: capitalize;
  @include if-device(tablet) {
    width: 100% !important;
    font-size: 30px;
  }
  @include if-device(largephone) {
    width: 100% !important;
    font-size: 30px;
  }
  @include if-device(phone) {
    width: 100% !important;
    font-size: 30px;
  }
}
