@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";
.cardArea {
  // border: 1px solid $eight_color;
  background-color: $white_color;
  // border-radius: 5px;
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: auto;
  overflow: hidden;
  position: relative;
  &__ribbonbottomright {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    top: -10px;
    right: -19px;
    &::before,
    ::after {
      position: absolute;
      z-index: -1;
      content: "";
      display: block;
      border: 5px solid #2980b9;
      border-top-color: transparent;
      border-right-color: transparent;
    }
    &::after {
      bottom: 0;
      right: 0;
    }
    &::before {
      top: 0;
      left: 0;
    }
    & > span {
      position: absolute;
      display: block;
      width: 225px;
      padding: 11px 0;
      background-color: #3498db;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      color: #fff;
      font: 700 12px/1 "Lato", sans-serif;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      text-transform: uppercase;
      text-align: center;
      left: -25px;
      top: 37px;
      transform: rotate(45deg);
    }
  }

  &__ImgArea {
    width: 100%;
    height: 220px;
    @include if-device(phone) {
      height: 220px !important;
    }
    @include if-device(tablet) {
      height: 220px !important;
    }
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  &__detaile {
    padding: 10px;
    &__smallTitle {
      cursor: pointer;
      line-height: 1.5em;
      color: $eight_color;
      font-size: 10px;
      letter-spacing: 1px;
      font-weight: 700;
      text-transform: uppercase;
    }
    &__name {
      font-size: 14px;
      margin: 3px 0;
      font-weight: 700;
    }
    &__tittle {
      font-size: 18px;
      color: black;
      text-transform: uppercase;
      margin: 10px 0;
    }
    &__paragraf {
      font-size: 13px;
      line-height: 19px;
      color: #8d8c8c;
      font-weight: 400;
    }
    &__notification {
      display: flex;
      column-gap: 30px;
      color: #8d8c8c;
      font-size: 12px;
      margin: 20px 0;
    }
  }
}
