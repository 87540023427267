.container {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: 600;
  & > div {
    width: 200px;
    height: 100px;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
}
