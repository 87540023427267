@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.button {
  background: $eight-color;
  text-transform: uppercase;
  color: #fff;
  height: 45px;
  width: 100%;
  border: 1px solid $eight-color;
  margin-top: 25px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  border-radius: 3px;

  &:hover {
    background-color: #fff !important;
    color: $eight-color !important;
    letter-spacing: 1px;
  }

  @include if-device(phone) {
    font-size: 16px !important;
    margin: 20px 0;
  }
  @include if-device(tablet) {
    // font-size: 16px !important;
    margin: 20px 0;
  }
  @include if-device(largephone) {
    margin: 20px 0;
  }
}
