.search {
  display: flex;
  align-items: center;

  position: relative;
  &__searchBox {
    width: 100%;
    height: 45px;
    border: 1px solid #fff;
    padding: 2px 10px;
    font-size: 18px;
    &:focus {
      outline: none;
      border: 1px solid #fff;
    }

    &::placeholder {
      font-size: 18px;
    }
  }

  &__magnifyGlass {
    position: absolute;
    height: 20px;
    width: 23px;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #000;
    font-weight: 700;

    & > img {
      width: 100%;
      height: 100%;
    }
  }
}
