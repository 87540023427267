@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.courseDetails {
  &__detailsArea {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 20px 150px;
    gap: 30px;
    @include if-device(phone) {
      padding: 20px 10px;
      grid-template-columns: repeat(1, 1fr);
    }
    @include if-device(largephone) {
      padding: 30px;
      grid-template-columns: repeat(2, 1fr);
    }
    @include if-device(tablet) {
      padding: 40px;
      grid-template-columns: repeat(3, 1fr);
    }
    @include if-device(desktop) {
      padding: 40px;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
.emptyMsg {
  display: inline-block;
  width: 90%;
  height: 350px;
  overflow: hidden;
  @include if-device(largephone) {
    height: 280px;
  }
  @include if-device(tablet) {
    height: 350px;
  }
  @include if-device(tablet) {
    height: 250px;
  }
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
