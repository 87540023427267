@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";
.graphology {
  width: 100%;
  padding: 50px 50px 80px;

  @include if-device(desktop) {
    padding: 50px;
  }
  @include if-device(tablet) {
    padding: 50px;
  }
  @include if-device(largephone) {
    padding: 50px;
  }
  @include if-device(phone) {
    padding: 30px 20px;
  }

  &__top {
    display: flex;
    align-items: center;
    column-gap: 40px;

    @include if-device(desktop) {
      align-items: flex-start;
      display: block;
    }
    @include if-device(tablet) {
      align-items: flex-start;
      display: block;
    }
    @include if-device(largephone) {
      align-items: flex-start;
    }
    @include if-device(phone) {
      align-items: flex-start;
      flex-wrap: wrap;
      row-gap: 20px;
    }

    &__left {
      display: flex;
      align-items: center;
      width: 100%;
      @include if-device(desktop) {
        flex-wrap: wrap;
        row-gap: 20px;
        width: 100%;
      }
      @include if-device(tablet) {
        flex-wrap: wrap;
        row-gap: 20px;
        width: 100%;
      }
      @include if-device(largephone) {
        flex-wrap: wrap;
        row-gap: 20px;
      }
      @include if-device(phone) {
        flex-wrap: wrap;
        row-gap: 20px;
        width: 100%;
      }
    }

    &__right {
      width: 50%;
      text-align: right;
      @include if-device(phone) {
        text-align: left;
      }
      @include if-device(desktop) {
        width: 100%;
      }
      @include if-device(tablet) {
        text-align: left;
        margin-top: 20px !important;
      }
    }
  }

  &__bottom {
    margin-top: 30px;
    display: flex;
    column-gap: 40px;
    justify-content: space-between;
    @include if-device(desktop) {
      flex-wrap: wrap;
      row-gap: 50px;
    }
    @include if-device(tablet) {
      flex-wrap: wrap;
      row-gap: 50px;
    }
    @include if-device(largephone) {
      flex-wrap: wrap;
      row-gap: 50px;
    }
    @include if-device(phone) {
      flex-wrap: wrap;
      row-gap: 50px;
    }

    &__left {
      width: 50%;

      @include if-device(desktop) {
        width: 100%;
      }
      @include if-device(tablet) {
        width: 100%;
      }
      @include if-device(largephone) {
        width: 100%;
      }
      @include if-device(phone) {
        width: 100%;
      }
      &__all {
        display: flex;
        align-items: center;
        position: absolute;
        column-gap: 5px;
        top: 8px;
        right: 8px;
        padding: 2px 6px;
        border-radius: 3px;
        color: $eight_color;
        cursor: pointer;
        & > input {
          width: 15px;
          height: 15px;
        }
      }
      &__lists {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
      }
    }

    &__right {
      flex: 1;
      @include if-device(desktop) {
        width: 100%;
      }
      @include if-device(tablet) {
        width: 100%;
      }
      @include if-device(largephone) {
        width: 100%;
      }
      @include if-device(phone) {
        width: 100%;
      }
    }
  }
}
.emptyMsg {
  display: inline-block;
  width: 90%;
  height: 350px;
  overflow: hidden;
  @include if-device(largephone) {
    height: 280px;
  }
  @include if-device(tablet) {
    height: 350px;
  }
  @include if-device(tablet) {
    height: 250px;
  }
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
