.catagoryArea {
  border: 2px solid #777;
  border-radius: 5px;
  width: 100%;
  padding: 15px;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background-color: #244348;
  }
  &:hover p {
    color: #fff;
  }
  &:hover &__radioButton label {
    color: #fff;
  }
  &__radioButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    & > label {
      font-size: 20px;
      color: black;
      font-weight: 500;
    }
    & > input {
      width: 18px;
      height: 18px;
    }
  }
  & > p {
    font-weight: 500;
    color: #777;
    font-size: 17px;
    line-height: 25px;
  }
}
