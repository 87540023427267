@import "../../../public/styles/variables";
.optionArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border-radius: 5px;
  padding: 0 10px;
  background: #e3edec;
  & > input {
    width: 25px;
    height: 25px;
  }
  &__labelArea {
    display: flex;
    align-items: center;
    gap: 10px;
    &__imgArea {
      width: 160px;
      height: 50px;
      overflow: hidden;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
