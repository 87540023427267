$first-color: #0c1023;
$second-color: #0b0e1e;
$third-color: #ca416e;
$forth-color: #219bf1;
$fifth-color: #252839;
$eight-color: #ff7010;
$seventh-color: #a5c9cf;
$text1: #b5bad3;
$eight-color: #1eb2a6;
$ninth-color: #f8f8f8;
$bg-one: #00695c;
$white-color: #fff;
$black-color: #000;
$tenth-color: #666666;

$opacity: 0.5;
$shawdow: 0px 15px 13px 0px rgba(0, 0, 0, 0.3);
$shawdow2: 0px 5px 25px -2px rgba(0, 0, 0, 0.06);
$shawdow3: rgba(0, 0, 0, 0.35) 0px 5px 15px;
$shawdow4: rgba(0, 0, 0, 0.16) 0px 1px 4px;

$font_1: "Berkshire Swash", sans-serif;
$font_2: "Roboto";
