@import "../../../public/styles/breakpoints";
.courseBanner {
  align-items: center;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: auto;
  padding: 5vw 150px;
  position: relative;

  @include if-device(desktop) {
    padding: 5vw 50px;
  }
  @include if-device(tablet) {
    padding: 5vw 50px;
  }
  @include if-device(largephone) {
    padding: 5vw 50px;
  }
  @include if-device(phone) {
    padding: 5vw 10px;
    height: auto;
  }

  &__left {
    position: relative;
    width: 50%;
    z-index: 1;
    @include if-device(desktop) {
      width: 70%;
    }
    @include if-device(tablet) {
      width: 100%;
    }
    @include if-device(largephone) {
      width: 100%;
    }
    @include if-device(phone) {
      width: 100%;
    }

    & > h2 {
      font-size: 4vw;
      color: #fff;
      margin-bottom: 1.4vw;
      @include if-device(largephone) {
        font-size: 5vw;
      }
      @include if-device(phone) {
        font-size: 8vw;
      }
    }

    & > p {
      line-height: 1.7vw;
      font-size: 1.1vw;
      color: #fff;
      @include if-device(largephone) {
        line-height: 3vw;
        font-size: 2vw;
      }
      @include if-device(phone) {
        line-height: 3.7vw;
        font-size: 2.8vw;
      }
      @include if-device(tablet) {
        line-height: 2.1vw;
        font-size: 1.6vw;
      }
    }
  }

  &__right {
    position: absolute;
    bottom: 0;
    right: 150px;

    @include if-device(phone) {
      right: 0;
    }
  }
}
