@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";
.dropBtn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  padding: 5px;
  border: 0.3px solid $white-color;
  border-radius: 8px;
  border: 0.2px solid $white-color;
  transition: 0.3s ease;
  cursor: pointer;
  background-color: $eight-color;
  // box-shadow: 0 0 10px 1px #d6caca;
  z-index: 99;
  & > img {
    width: 100%;
    height: 100%;
  }

  & > p {
    position: absolute;
    width: 150px;
    left: -160px;
    top: 7px;
    color: $white-color;
    font-size: 18px;
    font-weight: 500;
    border: 1px solid $white-color;
    border-radius: 4px;
    padding: 6px;
    display: none;
    background-color: #000;
  }
  &:hover p {
    display: block;
  }
}
.dropDown {
  position: fixed;
  bottom: 100px;
  right: 50px;
  z-index: 99;

  @include if-device(phone) {
    right: 15px;
  }
  @include if-device(largephone) {
    right: 18px;
  }
  @include if-device(tablet) {
    right: 30px;
  }
  &__drop1 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 5px;
    align-items: center;
    margin: 10px 0;
    border-radius: 7px;
    & > p {
      color: $white-color;
      font-size: 18px;
      font-weight: 500;
      border: 1px solid $white-color;
      border-radius: 4px;
      padding: 6px;
      display: none;
      background-color: #000;
    }
    &:hover p {
      display: block;
    }
    &__image {
      width: 50px;
      height: 50px;
      padding: 8px;
      cursor: pointer;
      background: rgba(255, 255, 255, 0.1);
      border: 0.3px solid $white-color;
      border-radius: 50%;
      transition: 0.3s ease;
      & > a {
        & > img {
          width: 100%;
          height: 100%;
        }
      }
      &:hover {
        box-shadow: 0 0 4px 1px $eight-color;
      }
    }
    &__imageX {
      width: 50px;
      height: 50px;
      padding: 8px;
      background: rgba(255, 255, 255, 0.1);
      border: 0.3px solid $white-color;
      border-radius: 50%;
      transition: 0.3s ease;
      cursor: pointer;
      & > img {
        width: 100%;
        height: 100%;
      }
      &:hover {
        box-shadow: 0 0 4px 1px rgb(237, 42, 42);
      }
    }
    &__imageC {
      width: 50px;
      height: 50px;
      padding: 5px;
      border: 0.3px solid $white-color;
      border-radius: 8px;
      border: 1px solid $eight-color;
      transition: 0.3s ease;
      cursor: pointer;
      background-color: $eight-color;
      box-shadow: 0 0 10px 1px #d6caca;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
