@import "../../../../public/styles/breakpoints";
@import "../../../../public/styles/variables";
.poojaDetails {
  &__bannerArea {
    padding: 80px 150px;
    background-image: linear-gradient(
        45deg,
        rgba(41, 57, 57, 0.946),
        rgba(234, 253, 254, 0)
      ),
      url(../../../assets/panditjibg.webp);
    // background-image: url(../../../assets/panditjibg.webp);
    color: #fff;
    line-height: 28px;
    @include if-device(phone) {
      padding: 20px;
    }
    @include if-device(largephone) {
      padding: 30px;
    }
    @include if-device(tablet) {
      padding: 40px;
    }
    & > p {
      margin-bottom: 25px;
      font-size: 18px;
      color: aqua;
      @include if-device(phone) {
        font-size: 18px;
        margin-bottom: 20px;
      }
      & > span {
        font-size: 18px;
        @include if-device(phone) {
          font-size: 16px;
        }
        & > i {
          font-size: 17px;
          margin: 0 4px;
        }
      }
      & > i {
        font-size: 17px;
        margin: 0 4px;
      }
    }
    & > h1 {
      font-size: 45px;
      text-transform: uppercase;
      @include if-device(phone) {
        font-size: 32px;
      }
    }
    & > h4 {
      font-size: 22px;
      margin: 12px 0;
      @include if-device(phone) {
        font-size: 18px;
        margin: 5px 0;
      }
    }
    &__rattingArea {
      font-size: 18px;
      display: flex;
      column-gap: 10px;
    }
  }
  &__detailsArea {
    padding: 20px 150px;
    @include if-device(phone) {
      padding: 20px 10px;
    }
    @include if-device(largephone) {
      padding: 30px;
    }
    @include if-device(tablet) {
      padding: 40px;
    }
    & > h1 {
      margin-bottom: 20px;
      color: #053834;
      text-align: center;
    }
    &__title {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 50px;
      @include if-device(largephone) {
        font-size: 30px;
      }

      @include if-device(phone) {
        font-size: 30px;
      }
      & > h1 {
        color: $eight-color;
        font-size: 68px;
        margin-bottom: 10px;
        font-family: $font_2;
        z-index: 1;
        position: relative;

        @include if-device(largephone) {
          font-size: 30px;
        }
        @include if-device(phone) {
          font-size: 30px;
          text-align: center;
          margin-top: 30px;
        }
      }
      &__arrowTitle {
        height: 1px;
        width: 20%;
        border: 0.2px solid $eight-color;
        border-radius: 1px;
        position: relative;
        @include if-device(largephone) {
          width: 30%;
        }

        @include if-device(phone) {
          width: 30%;
        }
        &::before {
          content: "";
          position: absolute;
          width: 50px;
          height: 6px;
          background-color: $eight-color;
          top: -3px;
          left: 0%;
          transform: translateX(-50%);
          border-radius: 2px;
          @include if-device(largephone) {
            width: 30px;
          }

          @include if-device(phone) {
            width: 30px;
          }
        }
      }
    }
    &__videoArea {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 25px;
      margin-bottom: 20px;

      @include if-device(phone) {
        grid-template-columns: 1fr;
        height: auto;
      }
      @include if-device(largephone) {
        grid-template-columns: 1fr;
        height: auto;
      }
      @include if-device(tablet) {
        grid-template-columns: 1fr;
        height: auto;
      }
      @include if-device(desktop) {
        grid-template-columns: 1fr;
        height: auto;
      }
      &__video {
        height: 420px;
        border: 1px solid #0e9a8e;
        background-color: black;
        @include if-device(phone) {
          height: 230px;
        }
        @include if-device(largephone) {
          height: 300px;
        }
        @include if-device(tablet) {
          height: 380px;
        }
        & > video {
          object-fit: cover;
        }
      }
      &__priceArea {
        padding: 10px;
        text-align: center;
        // border: 1px solid #0d9b8f;

        &__poojaType {
          margin-bottom: 20px;
          padding-bottom: 0;
          @include if-device(phone) {
            margin-bottom: 20px;
            padding-bottom: 0;
          }
          @include if-device(largephone) {
            margin-bottom: 20px;
            padding-bottom: 0;
          }
          @include if-device(tablet) {
            margin-bottom: 20px;
            padding-bottom: 0;
          }
          @include if-device(desktop) {
            margin-bottom: 20px;
            padding-bottom: 0;
          }
          & > h2 {
            font-size: 35px;
            color: #053834;
            @include if-device(phone) {
              font-size: 30px;
            }
          }
          & > p {
            margin: 5px 0;
            color: rgb(4, 127, 127);
          }
        }
        &__shareArea {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 25px;
          @include if-device(phone) {
            grid-template-columns: 1fr;
            row-gap: 0;
          }
          @include if-device(largephone) {
            grid-template-columns: 1fr 1fr;
            row-gap: 0;
          }
        }
      }
    }
    &__descriptionArea {
      &__cardArea {
        box-shadow: 0 0 10px 3px #d2cbcb;
        padding: 20px 10px;
        margin-bottom: 25px;
        & > h1 {
          color: #053834;
          margin-bottom: 10px;
          font-size: 28px;
          font-family: emoji;

          @include if-device(phone) {
            font-size: 20px;
            margin-bottom: 5px;
          }
        }
        & > p {
          font-size: 18px;
          line-height: 28px;
          color: #0d7f76;
          @include if-device(phone) {
            font-size: 16px;
          }
        }
        & > div {
          & > ul {
            list-style: decimal;
            padding-left: 35px;
            & > li {
              margin: 8px 0;
              font-size: 18px;
              line-height: 28px;
              color: #0d7f76;
              @include if-device(phone) {
                font-size: 16px;
              }
            }
          }
          & > ol {
            list-style: decimal;
            padding-left: 35px;
            & > li {
              margin: 8px 0;
              font-size: 18px;
              line-height: 28px;
              color: #0d7f76;
              @include if-device(phone) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
