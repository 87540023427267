@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.mainContaner {
  padding: 50px 70px;
  background-color: #fff;
  font-family: Philosopher;

  @include if-device(desktop) {
    padding: 50px;
  }
  @include if-device(tablet) {
    padding: 50px;
  }
  @include if-device(largephone) {
    padding: 50px;
  }
  @include if-device(phone) {
    padding: 30px 20px;
  }

  &__tittleArea {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    column-gap: 50px;
    justify-content: space-between;
    @include if-device(tablet) {
      flex-wrap: wrap;
      row-gap: 20px;
      width: 100%;
    }
    @include if-device(largephone) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(phone) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    &__tittle {
      display: flex;
      align-items: center;
      column-gap: 50px;
      justify-content: space-between;
      @include if-device(tablet) {
        flex-wrap: wrap;
        row-gap: 20px;
        width: 100%;
      }
      @include if-device(largephone) {
        flex-wrap: wrap;
        row-gap: 20px;
      }
      @include if-device(phone) {
        flex-wrap: wrap;
        row-gap: 20px;
      }
    }
    &__tabField {
      width: 25%;
      border-radius: 30px;
      overflow: hidden;
      border: 2px solid #c4c4c4;
    }
  }

  &__consultaintArea {
    width: 100%;
    display: flex;
    column-gap: 30px;
    @include if-device(desktop) {
      flex-wrap: wrap;
      row-gap: 30px;
    }
    @include if-device(tablet) {
      flex-wrap: wrap;
      row-gap: 30px;
    }
    @include if-device(largephone) {
      flex-wrap: wrap;
      row-gap: 30px;
    }
    @include if-device(phone) {
      flex-wrap: wrap;
      row-gap: 30px;
    }
    &__consultaintItem {
      width: 40%;

      @include if-device(desktop) {
        width: 100%;
      }
      @include if-device(tablet) {
        width: 100%;
      }
      @include if-device(largephone) {
        width: 100%;
      }
      @include if-device(phone) {
        width: 100%;
        flex-wrap: wrap;
      }
      &__all {
        display: flex;
        align-items: center;
        column-gap: 5px;
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 3px 6px;
        border-radius: 3px;
        color: $eight_color;
        cursor: pointer;
        z-index: 999999;
        & > input {
          width: 15px;
          height: 15px;
        }
      }
      &__bigImg {
        width: 200px;
        height: 200px;
        position: absolute;
        bottom: 0;
        right: 0;
        @include if-device(phone) {
          position: relative;
          width: 100% !important;
          height: 200px;
        }
        & > img {
          width: 100%;
          height: 100%;
          @include if-device(phone) {
            position: absolute;
            right: -20px;
            bottom: -20px;
            width: 260px;
            height: 200px;
          }
        }
      }
    }
    &__plan {
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 40px;
      @include if-device(desktop) {
        width: 100%;
      }
      @include if-device(tablet) {
        width: 100%;
      }
      @include if-device(largephone) {
        width: 100%;
      }
      @include if-device(phone) {
        width: 100%;
      }
      & > div {
        width: 100%;
      }
    }
  }
}
.emptyMsg {
  display: inline-block;
  width: 90%;
  height: 350px;
  overflow: hidden;
  @include if-device(largephone) {
    height: 280px;
  }
  @include if-device(tablet) {
    height: 350px;
  }
  @include if-device(tablet) {
    height: 250px;
  }
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
