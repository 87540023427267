@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.container {
  padding: 80px 150px;
  @include if-device(desktop) {
    padding: 80px 50px;
  }
  @include if-device(tablet) {
    padding: 50px;
  }
  @include if-device(largephone) {
    padding: 50px;
  }
  @include if-device(phone) {
    padding: 50px 20px;
  }
  &__tittleArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 40px;
    flex-wrap: wrap;
    @include if-device(phone) {
      display: block;
      margin-bottom: 30px;
    }
    &__tittle {
      width: 85%;
      margin: auto;
      &__fillterArea {
        position: relative;
        @include if-device(phone) {
          top: 15px;
        }
        & > span {
          padding: 8px;
          background-color: #109287;
          color: #fff;
          border: 1px solid #fff;
          border-radius: 4px;
          font-size: 17px;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            background-color: #fff;
            border-color: #109287;
            color: #109287;
          }
          & > i {
            margin-left: 5px;
          }
        }
        &__checkBox {
          position: absolute;
          z-index: 9;
          top: 35px;
          right: 0;
          width: 280px;
          padding: 3px;
          background-color: #fff;
          border-radius: 4px;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          @include if-device(phone) {
            left: 0;
            width: 240px;
          }
          & > div {
            // background-color: #109287;
            border-radius: 4px;
            margin-bottom: 3px;
            padding: 8px 5px;
            display: flex;
            align-items: center;
            gap: 10px;
            color: #fff;
            cursor: pointer;
            border: 1px solid #fff;
            transition: 0.3s;
            &:hover {
              background-color: #fff;
              color: #109287;
              border-color: #109287;
            }
            &:hover label {
              color: #109287;
            }
            & > input {
              width: 26px;
              height: 26px;
            }
            & > label {
              color: $eight-color;
              font-size: 18px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  &__courses {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px 20px;

    flex-wrap: wrap;
    @include if-device(desktop) {
      grid-template-columns: repeat(3, 1fr);
      gap: 20px 60px;
    }
    @include if-device(tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include if-device(largephone) {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px 20px;
    }
    @include if-device(phone) {
      display: flex;
      gap: 20px 20px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
.active {
  background-color: #fff !important;
  border-color: #109287 !important;
  color: #109287 !important;
}
.emptyMsg {
  display: inline-block;
  width: 90%;
  height: 350px;
  overflow: hidden;
  @include if-device(largephone) {
    height: 280px;
  }
  @include if-device(tablet) {
    height: 350px;
  }
  @include if-device(tablet) {
    height: 250px;
  }
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
