@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";
.blogingArea {
  // font-size: 50px;
  color: #155651;
  padding: 30px 0;
  background-color: $ninth-color;
  &__tittle {
    width: 85%;
    margin: auto;
    &__fillterArea {
      margin-bottom: 15px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      & > span {
        padding: 8px;
        background-color: #109287;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 4px;
        font-size: 17px;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          background-color: #fff;
          border-color: #109287;
          color: #109287;
        }
        & > i {
          margin-left: 5px;
        }
      }
      &__checkBox {
        position: absolute;
        z-index: 9;
        top: 40px;
        width: 20%;
        padding: 3px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        @include if-device(phone) {
          width: 80%;
        }
        @include if-device(largephone) {
          width: 50%;
        }
        @include if-device(tablet) {
          width: 40%;
        }
        @include if-device(desktop) {
          width: 30%;
        }
        & > div {
          background-color: #109287;
          border-radius: 4px;
          margin-bottom: 3px;
          padding: 8px 5px;
          display: flex;
          align-items: center;
          gap: 10px;
          color: #fff;
          cursor: pointer;
          border: 1px solid #fff;
          transition: 0.3s;
          &:hover {
            background-color: #fff;
            color: #109287;
            border-color: #109287;
          }
          &:hover label {
            color: #109287;
          }
          & > input {
            width: 26px;
            height: 26px;
          }
          & > label {
            color: #fff;
            font-size: 18px;
          }
        }
      }
    }
  }
  &__blogArea {
    width: 85%;
    margin: auto;
    display: flex;
    gap: 10px;
    @include if-device(phone) {
      display: block;
    }
    @include if-device(largephone) {
      display: block;
    }
    &__blotArticle {
      width: 280px;
      // border-right: 2px solid #109287;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

      @include if-device(phone) {
        margin-bottom: 40px;
        border: none;
        width: 100%;
      }
      @include if-device(largephone) {
        margin-bottom: 40px;
        border: none;
        width: 100%;
      }

      & > ul {
        list-style: none;
        padding-right: 5px;
        overflow-y: auto;
        max-height: 700px;
        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background-color: #fff;
        }

        &::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: $eight-color;
          border-radius: 5px;
        }
        & > li {
          padding: 10px;
          // box-shadow: 2px 2px 2px 2px #cccbcb;
          // margin-bottom: 8px;
          cursor: pointer;
          font-size: 1.1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          // background-color: #109287;
          color: #062926;
          border-bottom: 1px solid #109287;

          transition: 0.3s;
          &:hover {
            background-color: #109287;
            color: #fff;
          }
        }
      }
    }
    &__blog {
      flex: 1;
      padding-right: 10px;
      max-height: 700px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0px;
      }

      &::-webkit-scrollbar-track {
        background-color: #fff;
      }

      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: $eight-color;
      }
      &__topblog {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
        gap: 10px;
        background-color: #fff;
        @include if-device(largephone) {
          display: block;
        }
        @include if-device(tablet) {
          display: block;
        }
        @include if-device(phone) {
          display: block;
        }
        &__imgarea {
          width: 60%;
          height: 400px;
          @include if-device(largephone) {
            width: 100%;
            height: 300px;
          }
          @include if-device(tablet) {
            width: 100%;
          }
          @include if-device(phone) {
            width: 100%;
            height: 220px;
          }
          & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        &__detailsArea {
          flex: 1;
          &__detaile {
            padding: 15px 10px;
            &__smallTitle {
              cursor: pointer;
              line-height: 1.5em;
              color: $eight_color;
              font-size: 12px;
              letter-spacing: 1px;
              font-weight: 700;
              text-transform: uppercase;
            }
            &__name {
              font-size: 17px;
              margin: 3px 0;
              font-weight: 700;
            }
            &__tittle {
              font-size: 20px;
              color: black;
              text-transform: uppercase;
              margin: 10px 0;
            }
            &__paragraf {
              font-size: 17px;
              line-height: 25px;
              color: #8d8c8c;
              font-weight: 400;
            }
            &__notification {
              display: flex;
              column-gap: 30px;
              color: #8d8c8c;
              font-size: 15px;
              margin: 20px 0;
            }
          }
        }
      }
      &__blogCardArea {
        margin: auto;
        display: flex;
        justify-content: space-between;
        gap: 15px;
        flex-wrap: wrap;
        &__blogCard {
          width: 32%;
          @include if-device(phone) {
            width: 100%;
          }
          @include if-device(largephone) {
            width: 100%;
          }
          @include if-device(tablet) {
            width: 100%;
          }
          @include if-device(desktop) {
            width: 47%;
          }
        }
      }
    }
  }
}
.descript {
  & > h5 {
    text-decoration: underline;
    padding-bottom: 5px;
  }
  & > h2 {
    margin: 10px 0;
    color: #155651;
  }
  font-size: 20px;
  padding: 30px;
  color: #109287;
  text-align: center;
  & > p {
    line-height: 30px;
  }
}
.emptyMsg {
  display: inline-block;
  width: 100%;
  height: 350px;
  overflow: hidden;
  @include if-device(largephone) {
    height: 280px;
  }
  @include if-device(tablet) {
    height: 350px;
  }
  @include if-device(phone) {
    height: 250px;
  }
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.active {
  background-color: #fff !important;
  border-color: #109287 !important;
  color: #109287 !important;
}
