@import "../../../public/styles/variables";
label {
  font-size: 18px;
}

.input {
  width: 100%;
  height: 45px;
  border: 1px solid #8a898a;
  margin: 5px 0;
  font-size: 16px;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-bottom: 20px;
  margin-top: 10px;
  border-radius: 4px;
  padding: 1px 0;

  &__img1,
  &__img2 {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    cursor: pointer;

    & > img {
      height: 15px;
    }
  }

  & > input {
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    font-size: 16px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::placeholder {
      font-size: 16px;
    }

    &:focus {
      outline: none;
    }
  }
}
.guid {
  color: $eight_color;
  font-size: 13px;
  position: relative;
  top: -18px;
}
