@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";
.freekundliArea {
  padding: 30px 50px;
  @include if-device(phone) {
    padding: 10px;
  }
  &__kundliType {
    margin: 30px 0;
    padding: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    @include if-device(phone) {
      grid-template-columns: repeat(1, 1fr);
      padding: 0px;
      box-shadow: none;
    }
    @include if-device(largephone) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include if-device(tablet) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include if-device(desktop) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  &__tabArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 40px;
    flex-wrap: wrap;
    &__tab {
      flex: 1;
      @include if-device(phone) {
        width: 100%;
      }
      @include if-device(largephone) {
        width: 100%;
      }
      @include if-device(tablet) {
        width: 100%;
      }
    }
    &__tittle {
      width: 25%;
      @include if-device(phone) {
        width: 100%;
        margin-bottom: 30px;
      }
      @include if-device(largephone) {
        width: 100%;
        margin-bottom: 30px;
      }
      @include if-device(tablet) {
        width: 100%;
        margin-bottom: 30px;
      }
      @include if-device(desktop) {
        width: 35%;
      }
    }
    @include if-device(phone) {
      display: block !important;
    }
    @include if-device(largephone) {
      display: block !important;
    }
  }
}
.formArea {
  padding: 30px;
  & > h1 {
    text-align: center;
    margin-bottom: 20px;
    color: $eight-color;
    @include if-device(phone) {
      font-size: 22px;
    }
  }
  &__fieldArea {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    @include if-device(phone) {
      grid-template-columns: repeat(1, 1fr);
    }
    @include if-device(largephone) {
      grid-template-columns: repeat(2, 1fr);
    }
    &__select {
      margin: 5px 0;
      & > p {
        font-size: 18px;
        color: #444;
        margin-bottom: 6px;
      }
    }
  }
}
.datePicker {
  width: 100%;
  padding: 10px 20px;
  font-size: 18px;
  color: #333;
  border: 1px solid #1eb2a6;
  border-radius: 4px;
}

.apiDetai {
  padding: 10px;

  &__coming {
    display: block;
    text-align: center;
  }
  & > h1 {
    text-align: center;
    color: #1eb2a6;
    margin-bottom: 20px;
  }
  & > p {
    color: #1ca69b;
    line-height: 24px;
    text-align: center;
    margin-bottom: 15px;
  }
  & > h4 {
    text-align: center;
    margin-bottom: 10px;
  }
  & > ul {
    text-align: center;
    & > li {
      margin: 8px 0;
      font-size: 18px;
      color: #1ca69b;
    }
  }
  &__mahaDasa {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 20px;
    gap: 20px;
    // text-align: center;
    color: #1ca69b;

    & > div {
      & > h4 {
        margin-bottom: 20px;
        font-size: 24px;
      }
      & > p {
        font-size: 18px;
        margin: 4px 0;
      }
      & > ul {
        & > li {
          list-style: none;
          margin: 5px 0;
          font-size: 19px;
          color: #19847b;
        }
      }
    }
    @include if-device(phone) {
      grid-template-columns: 1fr;
    }
    @include if-device(largephone) {
      grid-template-columns: 1fr;
    }
  }
  &__dasapridection {
    & > h4 {
      color: #1ca69b;
      font-size: 30px;
      margin-bottom: 10px;
    }
    & > ul {
      & > li {
        font-size: 17px;
        color: #19847b;
        margin: 5px 0;
        line-height: 28px;
        & > span {
          color: #05675f;
          font-size: 22px;
          text-transform: capitalize;
        }
      }
    }
  }
  &__antardasha {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 20px;
    @include if-device(phone) {
      grid-template-columns: 1fr;
    }
    @include if-device(largephone) {
      grid-template-columns: 1fr 1fr;
    }
    & > ul {
      & > li {
        font-size: 18px;
        color: #19847b;
        margin: 5px 0;
        line-height: 28px;
        & > span {
          color: #076d65;
          font-size: 23px;
          text-transform: capitalize;
        }
      }
    }
  }
  &__paryantr {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 20px;
    gap: 30px;
    font-size: 19px;
    color: #1ca69b;
    line-height: 28px;
    @include if-device(phone) {
      grid-template-columns: 1fr;
    }
    @include if-device(largephone) {
      grid-template-columns: 1fr 1fr;
    }
  }
  &__paryantrList {
    & > ul {
      & > li {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        & > span {
          color: #067f75;
        }
        @include if-device(phone) {
          grid-template-columns: repeat(2, 1fr);
        }
        @include if-device(largephone) {
          grid-template-columns: repeat(5, 1fr);
        }
      }
    }
  }
}
.horoscopDetail {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  @include if-device(phone) {
    grid-template-columns: 1fr;
  }
  @include if-device(largephone) {
    grid-template-columns: 1fr;
  }
  & > ul {
    padding-left: 20px;
    & > li {
      & > span {
        color: rgb(10, 169, 169);
      }
      padding: 4px 0;
      font-size: 18px;
      color: #077f77;
    }
  }
}
.horo2 {
  & > p {
    color: #09a598;
    font-size: 17px;
    margin: 5px 0;
    line-height: 28px;
    & > span {
      color: #04786f;
      font-size: 18px;
      margin-right: 20px;
    }
  }
}
.ashtakvarga {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  font-size: 18px;
  line-height: 27px;
  color: #13514c;
  & > div {
    & > h3 {
      color: #333;
      margin-bottom: 15px;
    }
  }
  @include if-device(phone) {
    grid-template-columns: 1fr;
  }
  @include if-device(largephone) {
    grid-template-columns: 1fr;
  }
}
.binnashtak {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
  font-size: 18px;
  line-height: 27px;
  color: #13514c;
  & > ul {
    list-style: none;
    & > h3 {
      color: #333;
      margin-bottom: 15px;
    }
  }
  @include if-device(phone) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include if-device(largephone) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.panchang {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
  font-size: 18px;
  line-height: 27px;
  color: #13514c;
  & > ul {
    list-style: none;
    & > h3 {
      color: #333;
      margin-bottom: 15px;
    }
  }
  @include if-device(phone) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include if-device(largephone) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.imageArea {
  width: 200px;
  height: 200px;
  margin: auto;
  overflow: hidden;
  & > img {
    width: 100%;
    height: 100%;
  }
}
