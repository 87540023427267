@import "../../../../public/styles/variables";
.outgoing {
  max-width: 70%;
  background-color: $seventh-color;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin: 10px 14px 10px 0;
  padding: 10px;
  box-shadow: $shawdow3;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    right: -21.8px;
    bottom: 0;
    background: transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-left: 12px solid #69a3ad;
    border-bottom: 10px solid transparent;
  }

  &__time {
    font-size: 8px !important;
    color: #69a3ad !important;
    text-align: end !important;
  }

  & > p {
    font-size: 14px;
    color: $white-color;
    text-align: start;
  }
}

.cont {
  display: flex;
  justify-content: flex-end;
}
