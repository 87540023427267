@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.modalArea {
  width: 100%;

  padding: 50px;
  @include if-device(phone) {
    padding: 30px 20px;
  }

  &__modal {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    padding: 60px 40px;
    border-radius: 5px;
    margin-top: 30px;
    @include if-device(phone) {
      padding: 30px 8px;
    }
    &__tittle {
      margin-bottom: 30px;
    }
    &__formArea {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__select {
        margin: 15px 0;
        & > p {
          font-size: 18px;
          color: #444;
          margin-bottom: 6px;
        }
      }
      @include if-device(phone) {
        flex-wrap: wrap;
        row-gap: 20px;
      }
      @include if-device(largephone) {
        flex-wrap: wrap;
        row-gap: 20px;
      }
      @include if-device(tablet) {
        flex-wrap: wrap;
        row-gap: 20px;
      }
      @include if-device(desktop) {
        flex-wrap: wrap;
        row-gap: 20px;
      }

      &__input {
        width: 82%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: space-between;
        align-items: center;
        column-gap: 40px;
        @include if-device(phone) {
          width: 100%;
          grid-template-columns: repeat(1, 1fr);
          display: block;
        }
        @include if-device(largephone) {
          grid-template-columns: repeat(2, 1fr);
          column-gap: 20px;
          width: 100%;
        }
        @include if-device(tablet) {
          grid-template-columns: repeat(2, 1fr);
          width: 100%;
        }
        @include if-device(desktop) {
          width: 100%;
        }
      }

      &__formImage {
        width: 18%;
        position: relative;
        height: 100%;
        @include if-device(phone) {
          width: 100%;
        }
        @include if-device(largephone) {
          width: 100%;
        }
        @include if-device(tablet) {
          width: 100%;
        }
        @include if-device(desktop) {
          width: 100%;
        }

        &__image {
          width: 240px;
          height: 240px;
          overflow: hidden;
          position: absolute;
          right: -20px;
          @include if-device(desktop) {
            bottom: -130px;
          }
          @include if-device(tablet) {
            position: relative;
            margin: 0 0 0 auto;
          }
          @include if-device(largephone) {
            position: relative;
            margin: 0 0 0 auto;
          }
          @include if-device(phone) {
            position: relative;
            margin: 0 0 0 auto;
            right: 0;
            width: 200px !important;
            height: 200px !important;
          }
          & > img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    &__buttonArea {
      display: flex;
      align-items: center;
      column-gap: 100px;
      width: 82%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
      column-gap: 40px;
      @include if-device(phone) {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 20px;
      }
      @include if-device(largephone) {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 20px;
      }
      @include if-device(tablet) {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 20px;
      }
      @include if-device(desktop) {
        width: 100%;
      }
    }
  }
}
.datePicker {
  width: 100% !important;
  padding: 10px 20px;
  font-size: 18px;
  color: #333;
  border: 1px solid #1eb2a6;
  border-radius: 3px;
}

.paymentOption {
  padding: 15px;
  position: relative;
  & > h1 {
    text-align: center;
    color: $eight-color;
    margin-bottom: 25px;
    @include if-device(phone) {
      font-size: 20px;
      margin-top: 20px;
    }
  }
  &__cros {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 26px;
    width: 26px;
    cursor: pointer;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  &__option {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 20px;
    @include if-device(phone) {
      grid-template-columns: 1fr;
    }
  }
}
.showPayment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: #1eb2a6;
  border: 1px solid;
  padding: 10px;
  border-radius: 5px;
  margin-top: 25px;
  & > p {
    & > span {
      color: #767676;
      text-transform: capitalize;
    }
  }
  & > span {
    & > i {
      cursor: pointer;
    }
  }
}
