@import "../../../../public/styles/breakpoints";
@import "../../../../public/styles/variables";

.poojaTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  @include if-device(phone) {
    flex-wrap: wrap;
    justify-content: center;
  }

  & > h2 {
    font-size: 38px;
    color: $eight-color;
    @include if-device(phone) {
      margin-bottom: 20px;
    }
  }
  &__search {
    width: 30%;
    border: 2px solid $eight-color;
    @include if-device(phone) {
      width: 100%;
    }
    @include if-device(largephone) {
      width: 50%;
    }
  }
}
