@import "../../../../public/styles/breakpoints";
@import "../../../../public/styles/variables";

.astroCardArea {
  width: 100%;
  border: 2px solid $eight-color;
  border-radius: 10px;
  text-align: center;
  padding: 15px 11px;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 0 15px 5px #d9d6d6;

  &__ratting {
    position: absolute;
    top: 10px;
    right: 14px;
    font-size: 16px;
    color: #4c4949;
  }
  &__astroImage {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin: -68px auto 30px auto;
    border: 2px solid $eight-color;
    background-color: #fff;
    @include if-device(desktop) {
      width: 130px;
      height: 130px;
    }
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  &__astroDetail {
    text-align: center;
    &__name {
      margin: 15px 0;
      font-size: 35px;
      color: $eight-color;

      @include if-device(desktop) {
        font-size: 30px;
      }
    }
    &__exprience {
      color: #4c4949;
      font-size: 22px;
    }
    &__details {
      margin: 15px 0 15px 0;
      line-height: 28px;
      color: #4c4949;
      font-size: 15px;
    }
  }
  &__consultation {
    & > h1 {
      font-size: 80px;
      font-weight: 700;
      color: #403d3d;
      margin-bottom: 20px;
    }

    & > h4 {
      font-size: 26px;
      font-weight: 700;
      color: #155651;
    }
  }
  &__available {
    margin: 10px 0;
    & > p {
      font-size: 20px;
      font-weight: 700;
      color: #155651;
      margin-bottom: 5px;
    }
    &__btn {
      width: 100px;
      margin: auto;
      border: 2px solid $eight-color;
      border-radius: 30px;
      overflow: hidden;
      display: flex;
      column-gap: 1px;
      background-color: $eight-color;
      & > button {
        flex: 1;
        background-color: #fff;
        border: none;
        padding: 4px;
        cursor: pointer;
      }
      &__active {
        background-color: $eight-color !important;
        color: #fff;
      }
    }
  }
}
