@import "../../../public/styles/breakpoints";

.terms {
  padding: 50px 150px;

  @include if-device(desktop) {
    padding: 50px;
  }

  @include if-device(tablet) {
    padding: 50px;
  }

  @include if-device(largephone) {
    padding: 30px 50px;
  }

  @include if-device(phone) {
    padding: 20px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    font-family: "Poppins", sans-serif;
    text-decoration: underline;
    color: #155651;
    @include if-device(phone) {
      font-size: 30px;
    }
  }

  &__headlineCont {
    text-align: center;
    &__headline {
      font-size: 20px;
      font-family: "Poppins", sans-serif;
      color: #155651;
    }
  }

  &__para {
    text-align: justify;
    margin: 16px 0;

    &__ul {
      font-size: 16px;
      margin-left: 50px;

      &__nested {
        margin-left: 50px;
      }
    }

    &__title {
      text-decoration: underline;
      font-size: 20px;
      font-family: "Poppins", sans-serif;
    }
  }

  &__div {
    &__para {
      text-align: justify;
      margin: 16px 0;

      &__title {
        text-decoration: underline;
        font-size: 18px;
        font-family: "Poppins", sans-serif;
      }
    }
  }

  p,
  li {
    font-size: 16px;
    line-height: 25px;
    font-family: "Poppins", sans-serif;
  }
  b {
    font-family: "Poppins", sans-serif;
  }
}
