@import "../../../public/styles/variables";
.dragAndDrop {
  width: 100%;
  &__drop {
    border: 1px dashed $eight-color;
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 10px;
    position: relative;

    &__img {
      height: 50px;
      margin-bottom: 20px;
      & > img {
        height: 100%;
        width: 100%;
      }
    }

    & > p {
      font-weight: 700;

      & > span {
        font-weight: 500;
      }
    }

    &__file {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      opacity: 0;
    }
    &__files {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }

  &__para {
    font-size: 12px;
    text-align: center;
  }
}
