@import "../../../../public/styles/variables";
@import "../../../../public/styles/breakpoints";
.listCard {
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  //   background-color: $eight-color;
  background-color: #0f7971;
  &__image {
    clip-path: polygon(100% 0%, 88% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
    height: 80px;
    width: 40%;
    @include if-device(tablet) {
      height: 80px;
    }
    @include if-device(largephone) {
      height: 70px;
    }
    @include if-device(phone) {
      height: 60px;
    }
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  &__detail {
    // background-color: $eight-color;
    background-color: #0f7971;

    padding: 8px;
    height: 80px;
    flex: 1;
    @include if-device(tablet) {
      height: 80px;
    }
    @include if-device(largephone) {
      height: 70px;
    }
    @include if-device(phone) {
      height: 60px;
    }
    & > h4 {
      font-size: 12px;
      font-weight: 700;
      color: #fff;
      margin-bottom: 5px;
    }
    & > h6 {
      color: #f0ebeb;
      margin-bottom: 5px;
    }
    & > p {
      color: #fff;
      font-size: 12px;
    }
  }
}
