@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.backdrop {
  z-index: 99999999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.modal {
  z-index: 9999999999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 65%;
  background-color: #fff;
  border-radius: 7px;
  padding: 10px;
  //   overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $eight-color;
  }
  @include if-device(desktop) {
    width: 50% !important;
  }
  @include if-device(largephone) {
    width: 90% !important;
  }
  @include if-device(tablet) {
    width: 90% !important;
  }
  @include if-device(phone) {
    width: 90% !important;
    height: 60%;
  }
  & > button {
    position: fixed;
    z-index: 99999;
    top: 0;
  }
  & > footer {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    @include if-device(desktop) {
      grid-template-columns: 1fr 1fr;
    }
    @include if-device(largephone) {
      grid-template-columns: 1fr 1fr;
    }
    @include if-device(tablet) {
      grid-template-columns: 1fr 1fr;
    }
    @include if-device(phone) {
      grid-template-columns: 1fr;
    }
  }
}
