@import "../../../public/styles/variables";
.fieldArea {
  width: 100%;
  margin: 15px 0;
  position: relative;
  &__placeholder {
    // position: absolute;
    // left: 20px;
    // top: 12px;
    font-size: 18px;
    color: #525050;
    margin-bottom: 5px;
    display: inline-block;
    margin-left: 5px;
  }

  & > input {
    width: 100%;
    padding: 10px 20px;
    font-size: 18px;
    color: #333;
    border: 1px solid #aeabab;
    border-radius: 5px;

    &:focus {
      outline: #333 !important;
      border-color: #333 !important;
    }

    // &:hover &__placeholder {
    //   display: none;
    // }
    &::-webkit-calendar-picker-indicator {
      color: $eight-color;
      opacity: 0;
      z-index: 999;
      cursor: pointer;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__img {
    width: 20px;
    height: 20px;
    overflow: hidden;
    position: absolute;
    right: 20px;
    top: 40px;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
}
