@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";
.bookSlot {
  width: 100%;
  padding: 80px 0;
  position: relative;
  &__backBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1eb2a6;
    text-transform: uppercase;
    color: #fff;
    height: 30px;
    width: 50px;
    border: 1px solid #1eb2a6;
    cursor: pointer;
    transition: all 0.3s ease-in;
    border-radius: 3px;
    position: absolute;
    top: 11%;
    left: 6%;
    z-index: 99;
    font-size: 22px;
    &:hover {
      background-color: #fff;
      color: #1eb2a6;
    }
    @include if-device(phone) {
      left: 2%;
      top: 1%;
    }
    @include if-device(largephone) {
      left: 2%;
      top: 1%;
    }
    @include if-device(tablet) {
      left: 2%;
      top: 1%;
    }
  }
  &__container {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 40px 50px 30px 50px;
    width: 90%;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: relative;
    @include if-device(phone) {
      width: 100%;
      padding: 5px !important;
    }
    @include if-device(largephone) {
      width: 100%;
      padding: 40px 20px 30px 20px;
    }
    @include if-device(tablet) {
      width: 100%;
    }

    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      flex-wrap: wrap;
      row-gap: 10px;
      column-gap: 10px;
      @include if-device(phone) {
        justify-content: center;
      }

      & > h1 {
        color: #0a126e;
      }

      &__btn {
        width: 200px;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 30px;
      row-gap: 40px;
      @include if-device(phone) {
        flex-wrap: wrap;
      }
      @include if-device(largephone) {
        flex-wrap: wrap;
      }
      @include if-device(tablet) {
        flex-wrap: wrap;
      }
      &__calender {
        width: 60%;

        @include if-device(phone) {
          width: 100%;
        }
        @include if-device(largephone) {
          width: 100%;
        }
        @include if-device(tablet) {
          width: 100%;
        }
        &__cal {
          width: 100% !important;
          border: 1px solid #ccc !important;
          font-family: "Philospher", sans-serif !important;
          line-height: 31px !important;
          padding: 20px;
          border-radius: 10px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          min-height: 507px;
          @include if-device(phone) {
            padding: 2px;
          }
          @include if-device(largephone) {
            padding: 5px;
          }
          @include if-device(desktop) {
            padding: 8px;
          }
        }
      }

      &__timeSlot {
        width: 40%;
        overflow-y: auto;
        // background-color: #fff;
        border: 1px solid #ccc;
        padding: 20px;
        max-height: 500px;
        text-align: center;
        border-radius: 10px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        min-height: 507px;
        @include if-device(phone) {
          width: 100%;
          padding: 10px;
        }
        @include if-device(largephone) {
          width: 100%;
        }
        @include if-device(tablet) {
          width: 100%;
        }
        &__slot {
          &__utc {
            color: gray;
            font-size: 16px;
          }

          &__book {
            font-size: 24px;
            color: #0a126e;
            margin: 10px 0;
          }

          &__best {
            color: #000;
            font-size: 16px;
            margin-bottom: 10px;
          }

          &__ul {
            list-style: none;
            row-gap: 10px;
            display: flex;
            flex-direction: column;

            &__li {
              padding: 10px 0;
              width: 100%;
              margin: 0 auto;
              border: 1px solid #a7a7a7;
              border-radius: 5px;
              transition: all 0.3s ease-in-out;
              font-size: 18px;
              // @include if-device(phone) {
              //   width: 100%;
              // }

              &:hover {
                padding: 10px 0;
                width: 100%;
                margin: 0 auto;
                border: 1px solid $eight-color;
                border-radius: 5px;
                cursor: pointer;
                color: #fff;
                background-color: $eight-color;
              }
            }
          }
        }
      }
    }
  }
}

.active {
  padding: 10px 0;
  width: 100%;
  margin: 0 auto;
  border: 1px solid $eight-color;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  background-color: $eight-color;
  color: #fff;
}
.emptyMsg {
  display: inline-block;
  width: 90%;
  height: 350px;
  overflow: hidden;
  @include if-device(largephone) {
    height: 280px;
  }
  @include if-device(tablet) {
    height: 350px;
  }
  @include if-device(tablet) {
    height: 250px;
  }
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
