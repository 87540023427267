@import "../../../../public/styles/breakpoints";
@import "../../../../public/styles/variables";

.about {
  width: 100%;
  @include if-device(phone) {
    width: 100%;
  }
  @include if-device(tablet) {
    width: 100%;
  }
  @include if-device(largephone) {
    width: 100%;
  }
  & > div {
    & > h1 {
      font-size: 35px;
      margin-bottom: 40px;
      color: #155651;
      text-align: center;
      padding: 5px;
      @include if-device(phone) {
        font-size: 24px;
      }
      & > u {
        & > span {
          & > span {
            & > strong {
              @include if-device(phone) {
                font-size: 33px;
              }
              // & > img {
              //   width: 120px !important;
              //   height: 80px !important;
              // }
            }
          }
        }
      }
    }
    & > p {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 20px;
      @include if-device(phone) {
        font-size: 17px;
      }
      // & > strong {
      //   & > img {
      //     width: 120px !important;
      //     height: 80px !important;
      //   }
      // }
      // & > img {
      //   width: 120px !important;
      //   height: 80px !important;
      // }
    }
  }
  & > h2 {
    font-size: 35px;
    margin-bottom: 40px;
    color: #155651;
    text-align: center;
    padding: 5px;

    @include if-device(phone) {
      font-size: 40px;
    }
  }

  & > p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  &__bottom {
    &__ul {
      list-style-type: none;
      display: flex;
      align-items: center;
      column-gap: 10px;

      @include if-device(phone) {
        flex-wrap: wrap;
        row-gap: 20px;
      }

      &__li {
        & > a {
          color: $eight-color;
          text-decoration: none;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        height: 40px;
        width: 40px;
        border: 1px solid $eight-color;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}
.about img{
    width: 100% !important;
    object-fit: contain;
}