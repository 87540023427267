@import "../../../../public/styles/breakpoints";
@import "../../../..//public/styles/variables";

.commantab {
  display: inline-block;
  border-radius: 30px;
  overflow: hidden;
  border: 2px solid $eight-color;
  margin-bottom: 40px;
  background-color: #fff;

  @include if-device(phone) {
    width: 100%;
    border-radius: 10px;
  }

  @include if-device(largephone) {
    width: 100%;

    border-radius: 10px;
  }

  @include if-device(tablet) {
    width: 100%;

    border-radius: 10px;
  }

  & > span {
    display: inline-block;
    border-right: 2px solid $eight-color;

    @include if-device(phone) {
      border-bottom: 2px solid $eight-color;
      border-right: none;
      width: 100%;
    }
    @include if-device(largephone) {
      border-bottom: 2px solid $eight-color;
      border-right: none;
      width: 100%;
    }
    @include if-device(tablet) {
      border-bottom: 2px solid $eight-color;
      border-right: none;
      width: 100%;
    }

    &:last-child {
      border-right: none;
      border-bottom: none;
    }
  }

  &__tabArea {
    min-width: 100%;
    padding: 18px 22px;
    font-weight: 700;
    font-size: 17px;
    text-align: center;
    cursor: pointer;
    @include if-device(desktop) {
      padding: 13px 10px;
    }
    @include if-device(largephone) {
      padding: 13px 10px;
      width: 100% !important;
    }
    @include if-device(tablet) {
      padding: 13px 10px;
      width: 100% !important;
    }
    @include if-device(phone) {
      padding: 13px 10px;
      width: 100% !important;
    }
  }
}

.active {
  background-color: $eight-color;
  color: #fff;
}
