@import "../../../public/styles/variables";

.chat {
  width: 280px;
  height: 400px;
  background-color: $white-color;
  border-radius: 20px;
  box-shadow: $shawdow3;
  position: absolute;
  top:0 ;
  right: 20px;
  z-index: 100;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__mid {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;

    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}
