@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.tittle {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-family: Philosopher;
  font-size: 30px;
  font-weight: 600;
  color: $eight-color;

  @include if-device(phone) {
    width: 100% !important;
    font-size: 22px;
  }
  & > img {
    width: 50px;
    height: 50px;
  }
}
