.notFound {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &__container {
    &__content {
      display: flex;
      align-items: center;
      flex-direction: column;
      & > h1 {
        font-size: 7.5em;
        margin: 15px 0px;
        font-weight: bold;
      }
      & > h2 {
        font-weight: bold;
      }
    }
  }
}
