@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.readingArea {
  width: 100%;
  padding: 50px 80px;
  @include if-device(desktop) {
    padding: 50px;
  }
  @include if-device(tablet) {
    padding: 50px;
  }
  @include if-device(largephone) {
    padding: 50px;
  }
  @include if-device(phone) {
    padding: 30px 20px;
  }

  &__tittle {
    display: flex;
    align-items: center;
    column-gap: 60px;
    @include if-device(tablet) {
      flex-wrap: wrap;
      row-gap: 20px;
      width: 100%;
    }
    @include if-device(largephone) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(phone) {
      flex-wrap: wrap;
      row-gap: 20px;
    }

    & > span {
      @include if-device(tablet) {
        width: 100%;
      }
    }
  }
  &__TarroDeatailArea {
    border-radius: 10px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    padding: 30px 50px;
    margin: 40px 0;
    display: flex;
    column-gap: 30px;
    row-gap: 30px;
    flex-wrap: wrap;
    @include if-device(desktop) {
      flex-wrap: wrap;
    }
    @include if-device(largephone) {
      flex-wrap: wrap;
      padding: 30px;
    }
    @include if-device(tablet) {
      flex-wrap: wrap;
    }
    @include if-device(phone) {
      flex-wrap: wrap;
      padding: 20px 5px;
    }

    &__formArea {
      padding: 0 40px 0 0;
      border-right: 1px solid #444;
      position: relative;
      flex: 1;
      @include if-device(desktop) {
        border-bottom: 1px solid #444;
        border-right: none;
        padding: 0 0 40px 0;
        width: 100%;
      }
      @include if-device(tablet) {
        border-bottom: 1px solid #444;
        border-right: none;
        padding: 0 0 40px 0;
        width: 100%;
      }
      @include if-device(largephone) {
        border-bottom: 1px solid #444;
        border-right: none;
        padding: 0 0 40px 0;
        width: 100%;
      }
      @include if-device(phone) {
        border-bottom: 1px solid #444;
        border-right: none;
        padding: 0 0 40px 0;
        width: 100%;
      }

      & > h2 {
        margin: 20px 0;
      }
      &__timeSlot {
        width: 65%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 40px;
        & > p {
          margin: 30px 0 0 0;
          font-size: 18px;
          color: #1eb2a6;
          text-align: center;
          border: 0.5px solid #1eb2a6;
          padding: 8px;
        }
        @include if-device(largephone) {
          width: 100%;
          grid-template-columns: 1fr;
        }
        @include if-device(phone) {
          width: 100%;
          grid-template-columns: 1fr;
        }
        @include if-device(tablet) {
          width: 100%;
        }
      }

      &__requredform {
        display: grid;
        align-items: center;

        grid-template-columns: 1fr 1fr;
        column-gap: 40px;

        &__select {
          // margin-top: 25px;
          & > p {
            font-size: 18px;
            color: #444;
            margin-bottom: 6px;
          }
        }
        @include if-device(tablet) {
          grid-template-columns: 1fr 1fr;
          column-gap: 20px;
        }
        @include if-device(largephone) {
          grid-template-columns: 1fr;
          column-gap: 20px;
        }
        @include if-device(phone) {
          grid-template-columns: 1fr;
          column-gap: 20px;
          display: block;
        }
      }
      &__optinalform {
        width: 65%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 40px;
        @include if-device(tablet) {
          column-gap: 20px;
          width: 100%;
        }
        @include if-device(largephone) {
          column-gap: 20px;
          width: 100%;
        }
        @include if-device(phone) {
          column-gap: 20px;
          width: 100%;
          grid-template-columns: 1fr;
        }
      }
      &__cardArea {
        position: absolute;
        bottom: -30px;
        right: 5px;
        border-radius: 5px;
        width: 28%;
        text-align: center;
        @include if-device(desktop) {
          right: 0;
          bottom: 5px;
        }
        @include if-device(tablet) {
          display: none;
        }
        @include if-device(largephone) {
          display: none;
        }
        @include if-device(phone) {
          display: none;
        }
      }
    }
    &__planeAre {
      width: 36%;
      @include if-device(desktop) {
        width: 100%;
      }
      @include if-device(tablet) {
        width: 100%;
      }
      @include if-device(largephone) {
        width: 100%;
      }
      @include if-device(phone) {
        width: 100%;
      }
      & > div {
        margin: 42px 0;
      }
      & > h4 {
        display: flex;
        align-items: center;
        gap: 10px;

        & > span {
          display: inline-block;
          height: 40px;
          width: 35px;
          overflow: hidden;
          & > img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    &__btnArea {
      width: 100%;
      // display: flex;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      align-items: center;
      place-items: center;
      column-gap: 10px;
      row-gap: 20px;
      margin: 40px auto 10px auto;
      @include if-device(largephone) {
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
        width: 100%;
      }
      @include if-device(phone) {
        grid-template-columns: 1fr;
        column-gap: 10px;
        width: 100%;
      }
      @include if-device(tablet) {
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 10px;
        width: 100%;
      }
      & > p {
        font-size: 18px;
        color: #444;
        border: 1px solid #1eb2a6;
        padding: 10px;
        width: 100%;
      }
    }
  }
}
.datePicker {
  width: 100%;
  padding: 10px 20px;
  font-size: 18px;
  color: #333;
  border: 1px solid #1eb2a6;
  border-radius: 3px;
}
.emptyMsg {
  display: inline-block;
  width: 90%;
  height: 350px;
  overflow: hidden;
  @include if-device(largephone) {
    height: 280px;
  }
  @include if-device(tablet) {
    height: 350px;
  }
  @include if-device(tablet) {
    height: 250px;
  }
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.otherDate {
  margin-top: 12px;
  @include if-device(phone) {
    margin-top: 0;
  }
}
.paymentOption {
  padding: 15px;
  position: relative;
  & > h1 {
    text-align: center;
    color: $eight-color;
    margin-bottom: 25px;
    @include if-device(phone) {
      font-size: 20px;
      margin-top: 20px;
    }
  }
  &__cros {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 26px;
    width: 26px;
    cursor: pointer;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  &__option {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 20px;
    @include if-device(phone) {
      grid-template-columns: 1fr;
    }
  }
}
.showPayment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: #1eb2a6;
  border: 1px solid;
  padding: 10px;
  border-radius: 5px;
  margin-top: 25px;
  & > p {
    & > span {
      color: #767676;
      text-transform: capitalize;
    }
  }
  & > span {
    & > i {
      cursor: pointer;
    }
  }
}
