@import "../../../public/styles/breakpoints";

.modalArea {
  display: flex;
  align-items: center;
  justify-content: center;
  &__loder {
    position: absolute;
    top: 250px;
  }
  &__modal {
    width: 100%;
    padding: 30px 80px;
    background-color: #fff;
    position: relative;
    border-radius: 10px;

    @include if-device(tablet) {
      border-radius: 0;
    }

    @include if-device(largephone) {
      padding: 40px;
      border-radius: 0;
    }
    @include if-device(phone) {
      padding: 5px;
      border-radius: 0;
    }

    &__heading {
      text-align: center;
      margin-bottom: 40px;
    }
    &__service {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 40px;
      text-align: center;
      margin-bottom: 40px;
      @include if-device(tablet) {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }
      @include if-device(largephone) {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }
      @include if-device(phone) {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
      }
    }
    &__reportCard {
      width: 50%;
      margin: auto;
      @include if-device(tablet) {
        width: 100%;
      }
      @include if-device(largephone) {
        width: 100%;
      }
      @include if-device(phone) {
        width: 100%;
      }
    }
  }
}
