@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.link {
  padding: 0 30px;

  & > p {
    font-size: 18px;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 6px;
  }

  @include if-device(tablet) {
    padding: 0 10px;
  }
  @include if-device(largephone) {
    padding: 0 10px;
  }
  @include if-device(phone) {
    padding: 0 10px;
  }
}

.signup {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  padding: 50px 30px 0 30px;
  z-index: 1;
  min-height: 250px;

  @include if-device(desktop) {
    column-gap: 30px;
    margin: 0;
  }
  @include if-device(tablet) {
    column-gap: 30px;
    flex-wrap: wrap;
    margin: 0;
    padding: 30px 10px 0 10px;
  }
  @include if-device(largephone) {
    column-gap: 30px;
    flex-wrap: wrap;
    margin: 0;
    padding: 30px 10px 0 10px;
  }
  @include if-device(phone) {
    column-gap: 30px;
    flex-wrap: wrap;
    margin: 0;
    padding: 30px 10px 0 10px;
  }

  label {
    font-size: 18px;
    color: #000;
    text-transform: uppercase;
  }
  &__multiSelect {
    width: 100%;
    font-size: 18px;
    margin-top: 8px;
    @include if-device(phone) {
      margin-bottom: 10px;
    }
    @include if-device(largephone) {
      margin-bottom: 10px;
    }
    @include if-device(tablet) {
      margin-bottom: 10px;
    }
  }
  &__left {
    width: 50%;
    @include if-device(tablet) {
      width: 100%;
    }
    @include if-device(largephone) {
      width: 100%;
    }
    @include if-device(phone) {
      width: 100%;
    }
    &__signupAs {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      @include if-device(phone) {
        flex-wrap: wrap;
        row-gap: 10px;
      }

      &__text {
        width: 65%;
        font-weight: 600;
        font-size: 24px;
        color: $eight-color;
        @include if-device(desktop) {
          width: 55%;
        }
        @include if-device(largephone) {
          width: 55%;
        }
        @include if-device(phone) {
          width: 100%;
        }
      }
    }

    &__mid {
      display: flex;
      align-items: center;
      column-gap: 20px;
      @include if-device(phone) {
        flex-wrap: wrap;
        row-gap: 10px;
      }

      &__right,
      &__left {
        position: relative;
        width: 100%;
        z-index: 50;
      }
    }

    &__name {
      margin-bottom: 5px;
      position: relative;
    }

    &__genAge {
      @include if-device(phone) {
        flex-wrap: wrap;
        row-gap: 10px;
      }

      &__child {
        width: 100%;
        margin-bottom: 5px;
        & > p {
          font-size: 18px;
          color: #000;
          text-transform: uppercase;
          margin-bottom: 6px;
        }
      }
    }
  }
  &__right {
    width: 50%;

    @include if-device(tablet) {
      width: 100%;
    }
    @include if-device(largephone) {
      width: 100%;
    }
    @include if-device(phone) {
      width: 100%;
    }
    &__imageArea {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 20px;
      &__dragandDrop {
        width: 100%;
        @include if-device(phone) {
          width: 100%;
        }
      }
      @include if-device(phone) {
        display: block;
      }
      &__drag {
        flex: 1;
        display: flex;
        // align-items: center;
        column-gap: 20px;
        margin-bottom: 5px;
        @include if-device(phone) {
          flex-wrap: wrap;
          row-gap: 20px;
        }
      }
    }
    &__password {
      width: 100%;
    }
    &__cpassword {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      @include if-device(phone) {
        grid-template-columns: 1fr;
      }
    }
    & > h1 {
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 20px;
      color: $eight-color;
      font-size: 40px;
      @include if-device(phone) {
        display: none;
      }
      @include if-device(largephone) {
        display: none;
      }
      @include if-device(tablet) {
        display: none;
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0 35px;
    }
  }

  &__btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 40px;
    padding-bottom: 30px;
    margin-top: -0.1px;

    @include if-device(largephone) {
      column-gap: 20px;
    }
    @include if-device(phone) {
      column-gap: 20px;
      flex-wrap: wrap;
    }
  }
  &__loder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.datePicker {
  width: 100%;
  padding: 10px 20px;
  font-size: 18px;
  color: #333;
  border: 1px solid #1eb2a6;
  border-radius: 4px;
}
.mobileTittle {
  display: none;
  text-align: center;
  text-transform: uppercase;
  margin: 10px 0 10px 0;
  color: $eight-color;
  font-size: 30px;
  @include if-device(phone) {
    display: block;
  }
  @include if-device(largephone) {
    display: block;
  }
  @include if-device(tablet) {
    display: block;
  }
}
