.react-calendar button {
  border-radius: 1%;
  font-size: 18px;
  margin: auto !important;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    height: 40px !important;
    width: 40px !important;
  }
}

.swal-modal {
  z-index: 100 !important;
}

.react-calendar__navigation button {
  font-size: 25px;
}

.react-calendar__navigation {
  justify-content: space-between;
  gap: 2px;
}

.react-calendar__tile--now {
  background: #f0f0f0 !important;
  color: #1eb2a6 !important;
}

.react-calendar__tile--active {
  background: #1eb2a6 !important;
  color: #fff !important;
}

.react-calendar__tile:enabled:hover {
  background-color: #f0f0f0 !important;
  color: #333 !important;
}

.react-calendar__tile:enabled:focus {
  background-color: #1eb2a6 !important;
  color: #fff !important;
}

abbr[title] {
  text-decoration: none;
  font-size: 18px;
}

/* .react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent !important;
} */

.react-calendar__navigation__prev2-button {
  display: none !important;
}

.react-calendar__navigation__label {
  text-align: left;
  padding-left: 10px;
}

.react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden;
}

.react-calendar__month-view__weekdays,
.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.carousel.carousel-slider {
  overflow: visible !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.carousel .control-dots {
  bottom: -50px !important;
}

.carousel-root {
  height: 100% !important;
  width: 100%;
}

/* .carousel .slide > img {
  height: 330px !important;

} */

.carousel .slider-wrapper {
  height: 100% !important;
}

.carousel .control-disabled.control-arrow {
  opacity: 1 !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #000 !important;
}

.carousel .control-dots .dot.selected {
  background-color: #1eb2a6 !important;
  height: 12px !important;
  width: 12px !important;
  border: none !important;
}

.carousel .control-dots .dot {
  height: 12px !important;
  width: 12px !important;
  border: 1px solid #000;
}

.carousel.carousel-slider .control-arrow {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%);
  width: 100%;
}

/* .testimonial .control-next {
  position: absolute !important;
  height: 80px !important;
  width: 80px !important;
  opacity: 1 !important;
  background: #fff !important;
  border-radius: 50% !important;
  z-index: 10 !important;
  border: 1px solid #fff !important;
  right: -100px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px !important;
  color: #1eb2a6 !important;
} */

/* .testimonial .control-prev {
  position: absolute !important;
  height: 80px !important;
  width: 80px !important;
  opacity: 1 !important;
  background: #fff !important;
  border-radius: 50% !important;
  z-index: 10 !important;
  border: 1px solid #fff !important;
  left: -100px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px !important;
  color: #1eb2a6 !important;
} */

.testimonial .control-prev :active,
.testimonial .owl-nav button.owl-next:active {
  background-color: #fff !important;
  color: #1eb2a6 !important;
}

.testimonial .control-prev.control-arrow:before {
  border-right: 8px solid #1eb2a6 !important;
}

.testimonial .control-next.control-arrow:before {
  border-left: 8px solid #1eb2a6 !important;
}

.testimonial .control-prev span,
.testimonial .owl-nav button.owl-next span {
  margin-bottom: 10px;
}

@media screen and (max-width: 992px) {
  .carousel .slide img {
    height: 100% !important;
  }

  .testimonial .control-next {
    left: -15px !important;
    height: 40px !important;
    width: 40px !important;
  }

  .testimonial .control-prev {
    right: -15px !important;
    height: 40px !important;
    width: 40px !important;
  }
}

@media screen and (max-width: 600px) {
  .carousel .control-dots {
    bottom: 10px !important;
  }
}

.testimonial_slider {
  width: 100%;
}

.testimonial_slider .slick-slide>div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial_slider .slick-slide>div {
  height: 350px !important;
}

.testimonial_slider .slick-track {
  display: flex !important;
  justify-content: center;
  align-items: center;
  column-gap: 0px;
}

.home_slider .slick-track {
  display: flex !important;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}

.pandit_slider .slick-track {
  display: flex !important;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  width: 100% !important;
}

.testimonial_slider .slick-track {
  padding: 20px 0 !important;
}

.testimonial_slider .slick-center>div {
  height: 400px !important;
  position: relative;
  z-index: 1;
}

.testimonial_slider .slick-prev {
  position: absolute !important;
  height: 80px !important;
  width: 80px !important;
  opacity: 1 !important;
  /* background: #fff !important; */
  border-radius: 50% !important;
  z-index: 10 !important;
  border: 1px solid #fff !important;
  left: -100px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px !important;
  background-color: #1eb2a6 !important;
  border: 1px solid #1eb2a6;
}

.testimonial_slider .slick-next {
  position: absolute !important;
  height: 80px !important;
  width: 80px !important;
  opacity: 1 !important;
  /* background: #fff !important; */
  border-radius: 50% !important;
  z-index: 10 !important;
  border: 1px solid #fff !important;
  right: -100px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px !important;
  background-color: #1eb2a6 !important;
  border: 1px solid #1eb2a6;
}

.testimonial_slider .slick-prev:hover,
.testimonial_slider .slick-prev:focus,
.testimonial_slider .slick-next:hover,
.testimonial_slider .slick-next:focus {
  background-color: #1eb2a6 !important;
  border: 1px solid #1eb2a6;
}

@media screen and (max-width: 600px) {
  .testimonial_slider {
    width: 100%;
  }

  .testimonial_slider .slick-slide>div {
    height: 350px !important;
  }

  .testimonial_slider .slick-prev {
    top: 100% !important;
    left: 30% !important;
    height: 50px !important;
    width: 50px !important;
  }

  .testimonial_slider .slick-next {
    top: 100% !important;
    right: 30% !important;
    height: 50px !important;
    width: 50px !important;
  }
}

@media screen and (min-width: 150px) and (max-width: 390px) {
  .react-calendar__navigation button {
    font-size: 17px;
  }

  abbr[title] {
    font-size: 13px;
  }

  .react-calendar button {
    width: 20px !important;
    height: 20px !important;
  }

  .react-calendar__month-view__weekdays,
  .react-calendar__month-view__days {
    row-gap: 20px;
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .testimonial_slider {
    width: 100%;
  }

  .testimonial_slider .slick-slide>div {
    height: 350px !important;
  }

  .testimonial_slider .slick-prev {
    top: 100% !important;
    left: 35% !important;
    width: 50px !important;
    height: 50px !important;
  }

  .testimonial_slider .slick-next {
    top: 100% !important;
    right: 35% !important;
    width: 50px !important;
    height: 50px !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .testimonial_slider {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .testimonial_slider {
    width: 100%;
  }

  .testimonial_slider .slick-slide>div {
    height: 350px !important;
  }

  /* .testimonial_slider .slick-next {
    right: -30px !important;
  }

  .testimonial_slider .slick-prev {
    left: -30px !important;
  } */

  .testimonial_slider .slick-prev {
    top: 100% !important;
    left: 35% !important;
    width: 40px !important;
    height: 40px !important;
  }

  .testimonial_slider .slick-next {
    top: 100% !important;
    right: 35% !important;
    width: 40px !important;
    height: 40px !important;
  }
}

.owl-carousel .owl-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  position: relative;
  z-index: 9 !important;
}

.swal-modal {
  z-index: 9999 !important;
}

.swal-overlay {
  z-index: 1000000000;
}

.blog_slider {
  width: 100%;
}

.blog_slider .slick-slide>div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog_slider .slick-slide>div {
  height: 350px !important;
}

.blog_slider .slick-track {
  display: flex !important;
  justify-content: center;
  align-items: center;
  column-gap: 0px;
}

/* .blog_slider {
  width: 100% !important;
} */
.swal-icon {
  display: none;
}

.swal-title {
  display: none !important;
}

.swal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swal-overlay--show-modal .swal-modal {
  padding-top: 25px;
}

.react-multi-carousel-item {
  margin: 0 5px;
}

.searchWrapper {
  padding: 10px;
  margin-top: 10px;
  border-color: #1eb2a6;
}

.searchWrapper input {
  font-size: 18px;
}

.swal-text {
  text-align: center;
}