@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.timeSlot {
  display: flex;
  align-items: flex-end;
  column-gap: 10px;
  margin-bottom: 20px;

  @include if-device(phone) {
    flex-wrap: wrap;
  }

  &__child {
    width: 33%;
    position: relative;
    @include if-device(tablet) {
      flex-wrap: wrap;
    }
    @include if-device(largephone) {
      width: 33%;
    }
    @include if-device(phone) {
      width: 100%;
      margin-bottom: 10px;
    }

    & > p {
      margin-bottom: 10px;
      color: #000;
      font-size: 20px;
    }

    & > input {
      width: 100%;
      height: 45px;
      padding: 10px 10px;
      font-size: 20px;
      border: 1px solid $eight-color;
      border-radius: 5px;
      color: $eight-color;
      appearance: none;
      position: relative;
      &:focus {
        outline: none;
        border-color: $eight-color;
      }
      &::-webkit-calendar-picker-indicator {
        opacity: 0;
        font-size: 30px;
        z-index: 999;
        cursor: pointer;
      }
    }

    & > img {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 15px;
      top: 52%;
      cursor: pointer;
      z-index: 99;
    }

    &__subChild {
      display: flex;
      align-items: flex-start;
      padding: 10px;
      background-color: #fff;
      border: 1px solid #ccc;
      max-height: 200px;

      &__hour,
      &__min,
      &__session {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 33%;
        overflow-y: auto;
        max-height: 180px;
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background-color: #bdb7b7;
        }

        &::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: $eight-color;
        }
        & > p {
          padding: 10px;
        }
      }
    }
  }
}
