@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.planeArea {
  width: 100%;
  display: flex;
  align-items: center;
  @include if-device(phone) {
    flex-wrap: wrap;
  }
  @include if-device(largephone) {
    flex-wrap: wrap;
  }
  &__planNumber {
    width: 25%;
    background-color: $eight-color;
    border: 2px solid $eight-color;
    color: #fff;
    padding: 10px;
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    font-family: serif;

    @include if-device(phone) {
      width: 100% !important;
      text-align: left;
    }
    @include if-device(largephone) {
      width: 100%;
      text-align: left;
      font-size: 20px;
    }
  }

  &__plan {
    flex: 1;
    padding: 10px;
    border-top: 2px solid #868686;
    border-bottom: 2px solid #868686;
    border-right: 2px solid #868686;
    height: 44px;
    display: flex;
    align-items: center;
    @include if-device(phone) {
      width: 100%;
      border-left: 2px solid #868686;
      border-top: none;
    }
    @include if-device(largephone) {
      width: 100%;
      border-left: 2px solid #868686;
      border-top: none;
    }
  }
  &__icon {
    flex: 1;
    padding: 10px;
    border-top: 2px solid #868686;
    border-bottom: 2px solid #868686;
    border-right: 2px solid #868686;
    height: 44px;
    display: flex;
    align-items: center;
    position: relative;
    &::before {
      content: url("../../assets/newassets/check.gif");
      position: absolute;
      right: -20px;
      top: 58%;
      transform: translateY(-50%);
    }
    @include if-device(phone) {
      width: 100%;
      border-left: 2px solid #868686;
      border-top: none;
    }
    @include if-device(largephone) {
      width: 100%;
      border-left: 2px solid #868686;
      border-top: none;
    }
  }
}
