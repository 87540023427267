@import "../../../../public/styles/variables";

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: $seventh-color;
  padding: 0 6px;
  width: 100%;
  box-shadow: $shawdow4;

  & > p {
    font-size: 14px;
    text-align: center;
    width: 100%;
  }

  &__left {
    width: 83%;
    position: relative;
    &__text {
      height: 40px;
      width: 100%;
      border-radius: 20px;
      outline: none;
      border: none;
      padding: 0 35px 0 10px;
      font-size: 16px;
      font-family: $font_2;
      box-shadow: $shawdow4;

      &:focus {
        border: 1px solid $eight-color;
      }
    }

    &__file {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;

      & > label {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & > div {
        display: none;
      }
    }
  }

  &__right {
    width: 17%;
    height: 40px;
    width: 40px;
    border-radius: 50px;
    background-color: $eight-color;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: $shawdow4;
    border: 0.5px solid $white-color;
  }
}
