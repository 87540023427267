@import "../../../public/styles/breakpoints";

.pricingPolicy {
  padding: 50px 150px;

  @include if-device(desktop) {
    padding: 50px;
  }

  @include if-device(tablet) {
    padding: 50px;
  }

  @include if-device(largephone) {
    padding: 30px 50px;
  }

  @include if-device(phone) {
    padding: 20px;
  }
  & > h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    font-family: "Poppins", sans-serif;
    text-decoration: underline;
    color: #155651;
    @include if-device(phone) {
      font-size: 30px;
    }
  }

  & > p,
  b {
    text-align: justify;
    margin: 16px 0;
    font-size: 16px;
    line-height: 25px;
    font-family: "Poppins", sans-serif;
  }

  b {
    font-size: 18px;
  }
}
