@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.modalArea {
  width: 100%;
  background: transparent;
  display: grid;
  place-items: center;
  padding: 50px 120px;

  @include if-device(desktop) {
    padding: 50px;
  }
  @include if-device(tablet) {
    padding: 50px;
    display: block;
  }
  @include if-device(largephone) {
    padding: 50px;
    display: block;
  }
  @include if-device(phone) {
    padding: 30px 20px;
    display: block;
  }
  &__tittleArea {
    width: 100%;
    display: flex;
    align-items: center;
    @include if-device(tablet) {
      flex-wrap: wrap;
      row-gap: 20px;
      width: 100%;
    }
    @include if-device(largephone) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(phone) {
      flex-wrap: wrap;
      row-gap: 20px;
      // display: block;
    }

    &__tab {
      border: 2px solid #777;
      border-radius: 30px;
      overflow: hidden;
    }
  }
  &__modal {
    width: 100%;
    background-color: #fff;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    padding: 60px 30px;
    border-radius: 10px;
    margin: 40px 0;
    display: flex;
    column-gap: 50px;
    @include if-device(desktop) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(tablet) {
      flex-wrap: wrap;
      row-gap: 20px;
      width: 100%;
    }
    @include if-device(largephone) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(phone) {
      flex-wrap: wrap;
      row-gap: 20px;
      padding: 30px 5px;
    }

    &__left {
      width: 65%;
      @include if-device(desktop) {
        width: 100%;
        order: 2;
      }
      @include if-device(tablet) {
        width: 100%;
        order: 2;
      }
      @include if-device(largephone) {
        width: 100%;
        order: 2;
      }
      @include if-device(phone) {
        width: 100%;
        order: 2;
      }
      &__tittle {
        color: #222;
      }
      &__formArea {
        display: flex;
        justify-content: space-between;
        column-gap: 40px;
        @include if-device(phone) {
          display: block;
        }
        &__timeSlot {
          margin: 30px 0 0 0;
          font-size: 18px;
          color: #1eb2a6;
          text-align: center;
          border: 0.5px solid #1eb2a6;
          padding: 8px;
        }
        &__select {
          margin-top: 15px;
          & > p {
            font-size: 18px;
            color: #68574e;
            margin-bottom: 6px;
          }
        }

        &__formInput {
          flex: 1;
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 40px;
          padding: 30px 0;
          align-items: center;
          flex: 1;
          @include if-device(desktop) {
            // grid-template-columns: 1fr 1fr;
          }
          @include if-device(tablet) {
            grid-template-columns: 1fr 1fr;
          }
          @include if-device(largephone) {
            grid-template-columns: 1fr 1fr;
          }
          @include if-device(phone) {
            grid-template-columns: 1fr;
            display: block;
          }
        }
      }
      &__buttonArea {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 80px;
        margin-top: 40px;
        flex-wrap: wrap;

        @include if-device(desktop) {
          width: 100%;
        }
        @include if-device(tablet) {
          width: 100%;
        }
        @include if-device(largephone) {
          width: 100%;
          column-gap: 20px;
        }
        @include if-device(phone) {
          width: 100%;
          column-gap: 20px;
          flex-wrap: wrap;
        }

        & > div {
          width: 25%;
          text-align: center;

          @include if-device(largephone) {
            width: 100%;
          }
          @include if-device(phone) {
            width: 100%;
          }
        }
      }
    }

    &__right {
      width: 35%;
      @include if-device(desktop) {
        width: 100%;
        order: 1;
      }
      @include if-device(tablet) {
        width: 100%;
        order: 1;
      }
      @include if-device(largephone) {
        width: 100%;
        order: 1;
      }
      @include if-device(phone) {
        width: 100%;
        order: 1;
      }
      &__dragAndDrop {
        &__sample {
          display: flex;
          justify-content: space-between;
          align-items: center;

          & > h2 {
            color: #222;
          }

          &__images {
            display: flex;
            align-items: center;

            & > img {
              width: 50px;
            }
          }

          @include if-device(phone) {
            width: 100%;
            flex-wrap: wrap;
            column-gap: 20px;
            justify-content: center;
          }
        }
        &__dropField {
          width: 100%;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 10px 0;
          gap: 20px;

          & > div {
            width: 46%;
            @include if-device(phone) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.datePicker {
  width: 100% !important;
  padding: 10px 20px;
  font-size: 18px;
  color: #333;
  border: 1px solid #1eb2a6;
  border-radius: 3px;
}

.paymentOption {
  padding: 15px;
  position: relative;
  & > h1 {
    text-align: center;
    color: $eight-color;
    margin-bottom: 25px;
    @include if-device(phone) {
      font-size: 20px;
      margin-top: 20px;
    }
  }
  &__cros {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 26px;
    width: 26px;
    cursor: pointer;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  &__option {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 20px;
    @include if-device(phone) {
      grid-template-columns: 1fr;
    }
  }
}
.showPayment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: #1eb2a6;
  border: 1px solid;
  padding: 10px;
  border-radius: 5px;
  margin-top: 25px;
  & > p {
    & > span {
      color: #767676;
      text-transform: capitalize;
    }
  }
  & > span {
    & > i {
      cursor: pointer;
    }
  }
}
