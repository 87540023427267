@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.login {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  padding: 40px 30px 30px 30px;
  z-index: 1;
  margin: 0 auto;
  min-height: 250px;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.9;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px;
    z-index: -1;
    @include if-device(tablet) {
      border-radius: 10px 0 0 10px;
      height: 100%;
    }
    @include if-device(largephone) {
      border-radius: 10px 0 0 10px;
      height: 100%;
    }
    @include if-device(phone) {
      border-radius: 10px 0 0 10px;
      height: 100%;
    }
  }

  @include if-device(desktop) {
    column-gap: 30px;
    margin: 0;
  }
  @include if-device(tablet) {
    column-gap: 30px;
    flex-wrap: wrap;
    margin: 0;
  }
  @include if-device(largephone) {
    column-gap: 30px;
    flex-wrap: wrap;
    margin: 0;
    padding: 40px 10px 30px 10px;
  }
  @include if-device(phone) {
    column-gap: 30px;
    flex-wrap: wrap;
    margin: 0;
    padding: 40px 10px 30px 10px;
  }

  &__left {
    width: 50%;
    @include if-device(tablet) {
      width: 100%;
      text-align: center;
    }
    @include if-device(largephone) {
      width: 100%;
      text-align: center;
    }
    @include if-device(phone) {
      width: 100%;
      text-align: center;
    }
    & > h1 {
      text-transform: uppercase;
      margin-bottom: 50px;
      color: $eight-color;
      font-size: 40px;
      @include if-device(phone) {
        font-size: 32px;
        margin-bottom: 20px;
      }
    }

    & > h3 {
      margin-bottom: 30px;
      font-size: 30px;
      font-weight: 600;
      @include if-device(phone) {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }

    & > p {
      line-height: 30px;
    }

    &__ganesh {
      @include if-device(largephone) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 170px;
        margin: auto;
        & > img {
          width: 100%;
        }
      }
      @include if-device(phone) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 170px;
        margin: auto;
        & > img {
          width: 100%;
        }
      }
      @include if-device(tablet) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 170px;
        margin: auto;
        & > img {
          width: 100%;
        }
      }
    }
  }

  &__right {
    width: 50%;

    @include if-device(tablet) {
      width: 100%;
    }
    @include if-device(largephone) {
      width: 100%;
    }
    @include if-device(phone) {
      width: 100%;
    }
    &__child {
      & > p {
        font-size: 18px;
        color: #5c5a5a;
      }
    }
    &__loginAs {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      @include if-device(phone) {
        flex-wrap: wrap;
        row-gap: 10px;
      }

      &__text {
        // width: 30%;
        font-weight: 600;
        font-size: 24px;
        color: $eight-color;
        @include if-device(desktop) {
          width: 45%;
        }
        @include if-device(largephone) {
          width: 45%;
        }
        @include if-device(phone) {
          width: 100%;
        }
      }
    }

    &__forgot {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include if-device(phone) {
        flex-wrap: wrap;
        row-gap: 10px;
      }
      &__keep {
        display: flex;
        align-items: center;
        column-gap: 5px;

        & > input {
          accent-color: #950606;
          cursor: pointer;
          height: 15px;
          width: 15px;
        }
        & > p {
          color: #8a8e9f;
          cursor: pointer;
          @include if-device(phone) {
            font-size: 14px;
          }
        }
      }

      & > p {
        color: #950606;
        cursor: pointer;
        &:hover {
          color: $eight-color;
        }
        @include if-device(phone) {
          font-size: 14px;
        }
      }
    }

    &__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0;
      @include if-device(phone) {
        flex-wrap: wrap;
        row-gap: 10px;
        justify-content: center;
      }

      &__btn {
        border: 1px solid #8a8e9f;
        height: 40px;
        width: 150px;
        border-radius: 4px;
        color: #950606;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
        @include if-device(phone) {
          width: 100%;
        }
      }

      &__or {
        display: flex;
        align-items: center;
        column-gap: 3px;
        @include if-device(phone) {
          width: 100%;
          justify-content: center;
        }

        &__text {
          position: relative;

          &::after {
            height: 1px;
            background-color: #000;
            width: 10px;
            position: absolute;
            top: 60%;
            right: -15px;
            transform: translateY(-50%);
            content: "";
          }

          &::before {
            height: 1px;
            background-color: #000;
            width: 10px;
            position: absolute;
            top: 60%;
            left: -15px;
            transform: translateY(-50%);
            content: "";
          }
        }
      }

      &__social {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        align-items: center;
        & > p {
          font-size: 12px;
          color: #000;
        }

        & > ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          list-style: none;
          column-gap: 20px;

          & > li {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            background-color: $eight-color;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
    &__centerTop {
      align-items: center;
      text-align: center;
      margin: 20px 0;

      &__or {
        display: flex;
        align-items: center;
        column-gap: 3px;
        justify-content: center;
        margin: 5px 0;
        &__text {
          position: relative;

          &::after {
            height: 1px;
            background-color: #000;
            width: 10px;
            position: absolute;
            top: 60%;
            right: -15px;
            transform: translateY(-50%);
            content: "";
          }

          &::before {
            height: 1px;
            background-color: #000;
            width: 10px;
            position: absolute;
            top: 60%;
            left: -15px;
            transform: translateY(-50%);
            content: "";
          }
        }
      }

      &__social {
        & > p {
          font-size: 17px;
          color: #950606;
        }
      }
    }

    &__signup {
      & > p {
        color: #8a8e9f;

        & > span {
          color: #950606;
          cursor: pointer;
          &:hover {
            color: $eight-color;
          }
        }
      }
    }
  }
  &__loder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.otpArea {
  justify-content: center !important;
}
.resend {
  & > button {
    padding: 10px;
    background-color: $eight_color;
    border: none;
    border-radius: 4px;
    color: #fff;
  }
}
.disabled {
  & > button {
    padding: 10px;
    background-color: #9d9c9c;
    border: none;
    border-radius: 4px;
    color: whitesmoke;
  }
}
