@import "../../../public//styles/breakpoints";
@import "../../../public//styles/variables";

.modalArea {
  width: 100%;
  background: transparent;
  display: grid;

  padding: 50px 100px;

  @include if-device(desktop) {
    padding: 50px;
  }
  @include if-device(tablet) {
    padding: 50px;
  }
  @include if-device(largephone) {
    padding: 50px;
  }
  @include if-device(phone) {
    padding: 20px;
    display: block;
  }
  &__telephonicArea{
    text-align: center;
    margin-top: 10px;
   &>span{
     cursor: pointer;
    font-size: 17px;
    font-weight: 700;
    padding: 18px 22px;
    text-align: center;
    width: 260px;
    color: #fff;
    background-color: $eight-color;
    overflow: hidden;
    display: inline-block;
    border-radius: 30px;
   }
  }
  &__modal {
    width: 100%;
    background-color: #fff;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    padding: 60px 30px;
    border-radius: 10px;
    margin: 40px 0;
    @include if-device(phone) {
      padding: 30px 5px;
    }
    &__formArea {
      display: flex;
      justify-content: space-between;
      column-gap: 50px;
      @include if-device(desktop) {
        flex-wrap: wrap;
        row-gap: 30px;
      }
      @include if-device(tablet) {
        flex-wrap: wrap;
        row-gap: 30px;
      }
      @include if-device(largephone) {
        flex-wrap: wrap;
        row-gap: 30px;
      }
      @include if-device(phone) {
        flex-wrap: wrap;
        row-gap: 30px;
      }

      &__detail {
        @include if-device(desktop) {
          width: 100%;
        }
        @include if-device(tablet) {
          width: 100%;
        }
        @include if-device(largephone) {
          width: 100%;
        }
        @include if-device(phone) {
          width: 100%;
        }
        &__formField {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 20px;
          @include if-device(largephone) {
            column-gap: 30px;
          }
          @include if-device(phone) {
            grid-template-columns: repeat(1, 1fr);
            display: block;
          }
          &__datepeker {
            margin: auto 0 !important;
            & > p {
              font-size: 18px;
              color: #444;
              margin-bottom: 7px;
            }
          }
          &__select {
            margin: 15px 0;
            & > p {
              font-size: 18px;
              color: #444;
              margin-bottom: 7px;
            }
            // &__timeSlot {
            //   font-size: 18px;
            //   color: #1eb2a6;
            //   text-align: center;
            //   border: 0.5px solid #1eb2a6;
            //   padding: 8px;
            //   margin-top: 25px;
            // }
          }
        }
      }
    }
    &__buttonArea {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 40px;
      margin-top: 30px;
      row-gap: 20px;
      &__timeSlot {
        font-size: 18px;
        color: #1eb2a6;
        text-align: center;
        border: 0.5px solid #1eb2a6;
        padding: 8px;
        // margin-top: 25px;
      }
      @include if-device(largephone) {
        column-gap: 20px;
      }
      @include if-device(phone) {
        column-gap: 20px;
      }
      & > div {
        width: 20%;
        @include if-device(largephone) {
          width: 40%;
          column-gap: 20px;
        }
        @include if-device(phone) {
          width: 100%;
          column-gap: 20px;
        }
        @include if-device(desktop) {
          width: 30%;
          column-gap: 20px;
        }
        @include if-device(tablet) {
          width: 40%;
          column-gap: 20px;
        }
      }
    }
  }
}
.datePicker {
  width: 100% !important;
  padding: 10px 20px;
  font-size: 18px;
  color: #333;
  border: 1px solid #1eb2a6;
  border-radius: 4px;
  margin: auto;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.modal {
  height: auto !important;
}
.paymentOption {
  padding: 15px;
  position: relative;
  & > h1 {
    text-align: center;
    color: $eight-color;
    margin-bottom: 25px;
    @include if-device(phone) {
      font-size: 20px;
      margin-top: 20px;
    }
  }
  &__cros {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 26px;
    width: 26px;
    cursor: pointer;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  &__option {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 20px;
    @include if-device(phone) {
      grid-template-columns: 1fr;
    }
  }
}
.showPayment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: #1eb2a6;
  border: 1px solid;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  @include if-device(phone) {
    margin-top: 25px;
  }
  @include if-device(largephone) {
    margin-top: 25px;
  }
  & > p {
    & > span {
      color: #767676;
      text-transform: capitalize;
    }
  }
  & > span {
    & > i {
      cursor: pointer;
    }
  }
}
.showPayOption {
  display: none;
  @include if-device(phone) {
    display: block;
  }
  @include if-device(largephone) {
    display: block;
  }
}
.hidePayOption {
  @include if-device(phone) {
    display: none;
  }
  @include if-device(largephone) {
    display: none;
  }
}
