@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";
.selectArea {
  width: 100%;
  @include if-device(phone) {
    margin-bottom: 8px;
    margin-top: 8px;
  }
  @include if-device(largephone) {
    margin-bottom: 6px;
    margin-top: 8px;
  }
  &__select {
    width: 100%;
    padding: 8px;
    border: 1px solid $eight-color;
    font-size: 18px;
    outline-color: #217ad2;
    border-radius: 4px;
    margin-top: 5px;
  }
}
