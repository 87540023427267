@import "../../public/styles/breakpoints";
@import "../../public/styles/variables";

.backdrop {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.modal {
  z-index: 1001;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-height: 90%;
  background-color: #fff;
  border-radius: 7px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $eight-color;
  }
  @include if-device(desktop) {
    width: 90% !important;
    max-height: 90%;
  }
  @include if-device(largephone) {
    width: 90% !important;
    max-height: 90%;
  }
  @include if-device(tablet) {
    width: 90% !important;
    max-height: 90%;
  }
  @include if-device(phone) {
    width: 90% !important;
    max-height: 90%;
  }
  & > button {
    position: fixed;
    z-index: 99999;
    top: 0;
  }
}
