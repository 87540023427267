@import "../../../../public/styles/variables";

.itemArea {
  width: 100%;
  display: flex;
  align-items: flex-start;
  column-gap: 20px;
  margin: 10px 0;
  position: relative;
  z-index: 77;

  & > input {
    width: 18px;
    height: 18px;
    accent-color: $eight-color;
  }
  & > label {
    font-size: 16px;
    font-weight: 500;
    color: #444;
    text-transform: capitalize;
  }
}
