@import "../../../../public/styles/variables";
.header {
  height: 50px;
  background-color: $eight-color;
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: $shawdow4;

  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    column-gap: 10px;

    & > div {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      border: 1px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      & > p {
        color: #fff;
      }
    }
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 5px;
    &__user {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      overflow: hidden;

      & > img {
        height: 100%;
        width: 100%;
      }
    }

    &__username {
      font-size: 16px;
      font-family: $font_2;
      color: $white-color;
      text-align: start;
    }

    &__div {
      display: flex;
      align-items: center;
      &__status {
        height: 8px;
        width: 8px;
        border-radius: 5px;
      }
      &__userStatus {
        font-size: 12px;
        position: relative;
        padding-left: 3px;
        color: $white-color;
        font-style: italic;
      }
    }
  }
}
