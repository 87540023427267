.close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
  cursor: pointer;
  & > img {
    width: 15px;
    height: 15px;
  }
}
