@import "../../../../public/styles/breakpoints";
@import "../../../../public/styles/variables";
.cardArea {
  padding: 10px;
  display: block;
  margin: 10px 0;
  border-radius: 10px;
  column-gap: 20px;
  row-gap: 20px;
  box-shadow: 0 0 8px 1px #d2cbcb;
  @include if-device(phone) {
    display: block;
  }
  &__imgArea {
    width: 100%;
    height: 180px;
    border-radius: 10px;
    border: 1px solid #888;
    overflow: hidden;
    position: relative;
    & > span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(45deg, rgb(90 229 194 / 22%), rgb(194 3 3 / 21%));
      font-size: 40px;
      color: #1eb2a6;
    }
    @include if-device(phone) {
      width: 100%;
      margin-bottom: 10px;
      height: 220px;
    }
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  &__detailArea {
    flex: 1;
    text-align: left;
    margin-top: 10px;
    & > h2 {
      color: #1eb2a6;
      margin-bottom: 5px;
      font-size: 23px;
    }
    &__detail {
      font-size: 16px;
      margin: 5px 0;
      & > span {
        font-size: 17px;
        color: $eight-color;
        margin-right: 10px;
      }
    }
    &__notification {
      display: flex;
      column-gap: 20px;
      color: #8d8c8c;
      font-size: 16px;
      margin: 5px 0;
    }
  }
}
