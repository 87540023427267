@import "../../../../public/styles/breakpoints";

.heading {
  display: flex;
  align-items: center;
  column-gap: 30px;
  font-size: 30px;
  font-weight: 700;

  @include if-device(phone) {
    width: 100%;
    flex-wrap: wrap;
    row-gap: 20px;
  }
  &__img {
    width: 50px;
    height: 50px;
    overflow: hidden;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  &__orangeText {
    color: #a73500;
  }
  &__blueText {
    color: #222344;
  }
  &__maroonText {
    color: #970505;
  }
}
