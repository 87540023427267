@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.mainContaner {
  padding: 50px 80px;
  background-color: #fff;
  font-family: Philosopher;

  @include if-device(desktop) {
    padding: 50px;
  }
  @include if-device(tablet) {
    padding: 50px;
  }
  @include if-device(largephone) {
    padding: 50px;
  }
  @include if-device(phone) {
    padding: 30px 20px;
  }
  &__referBtn {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
    @include if-device(largephone) {
      display: block;
    }
    @include if-device(phone) {
      display: block;
    }
    @include if-device(tablet) {
      display: block;
    }
    @include if-device(desktop) {
      // display: block;
    }
  }
  &__tittleArea {
    margin-bottom: 50px;
  }

  &__consultaintArea {
    width: 100%;
    display: flex;
    column-gap: 30px;
    position: relative;
    @include if-device(desktop) {
      flex-wrap: wrap;
      row-gap: 30px;
    }
    @include if-device(tablet) {
      flex-wrap: wrap;
      row-gap: 30px;
    }
    @include if-device(largephone) {
      flex-wrap: wrap;
      row-gap: 30px;
    }
    @include if-device(phone) {
      flex-wrap: wrap;
      row-gap: 30px;
    }

    &__plan {
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 40px;
      @include if-device(desktop) {
        width: 100%;
      }
      @include if-device(tablet) {
        width: 100%;
      }
      @include if-device(largephone) {
        width: 100%;
      }
      @include if-device(phone) {
        width: 100%;
      }
      & > div {
        width: 100%;
      }
    }
  }
}
.emptyMsg {
  display: inline-block;
  width: 90%;
  height: 350px;
  overflow: hidden;
  @include if-device(largephone) {
    height: 280px;
  }
  @include if-device(tablet) {
    height: 350px;
  }
  @include if-device(tablet) {
    height: 250px;
  }
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
