@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.panditJiModal {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
  margin: 50px 30px;
  // height: 460px;
  @include if-device(phone) {
    grid-template-columns: 1fr;
    row-gap: 50px;
    margin: 30px 0px !important;
    display: block;
  }
  @include if-device(largephone) {
    grid-template-columns: 1fr;
    row-gap: 50px;
  }
  @include if-device(tablet) {
    grid-template-columns: 1fr;
    row-gap: 50px;
  }
  &__left {
    overflow-y: auto;
    height: 700px;
    border: 1px solid #14b3b6;
    padding: 5px;
    @include if-device(phone) {
      margin-bottom: 30px;
    }
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: $eight-color;
    }
    &__pooja {
      width: 100%;
      height: 280px;
      margin-bottom: 30px;
      border-radius: 10px;
      overflow: hidden;
      & > img {
        width: 100%;
        height: 100%;
      }
      @include if-device(phone) {
        height: 210px;
      }
    }

    & > h1 {
      margin-bottom: 20px;
    }

    & > p {
      font-size: 16px;
      line-height: 30px;
    }
  }
  &__right {
    &__slot {
      background-color: $eight-color;
      color: #fff;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      & > p {
        font-size: 20px;
        font-weight: 600;
        @include if-device(phone) {
          font-size: 14px;
        }
      }
    }
    &__select {
      margin: 15px 0;
      & > p {
        font-size: 18px;
        color: #444;
        margin-bottom: 7px;
      }
    }
  }
}
.datePicker {
  width: 100%;
  padding: 10px 20px;
  font-size: 18px;
  color: #333;
  border: 1px solid #1eb2a6;
  border-radius: 4px;
}
.paymentOption {
  padding: 15px;
  position: relative;
  & > h1 {
    text-align: center;
    color: $eight-color;
    margin-bottom: 25px;
    @include if-device(phone) {
      font-size: 20px;
      margin-top: 20px;
    }
  }
  &__cros {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 26px;
    width: 26px;
    cursor: pointer;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  &__option {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 20px;
    @include if-device(phone) {
      grid-template-columns: 1fr;
    }
  }
}
.showPayment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: #1eb2a6;
  border: 1px solid;
  padding: 10px;
  border-radius: 5px;
  margin-top: 25px;
  & > p {
    & > span {
      color: #767676;
      text-transform: capitalize;
    }
  }
  & > span {
    & > i {
      cursor: pointer;
    }
  }
}
