@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.aboutUs {
  margin: -80px 150px 80px;
  padding: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  z-index: 1;
  position: relative;
  background-color: #fff;

  @include if-device(phone) {
    margin: -50px 20px 50px;
    flex-wrap: wrap;
    padding: 20px;
  }

  @include if-device(desktop) {
    margin: -80px 50px 80px;
  }
  @include if-device(tablet) {
    margin: -50px 50px 50px;
    flex-wrap: wrap;
  }
  @include if-device(largephone) {
    margin: -50px 50px 50px;
    flex-wrap: wrap;
  }

  &__right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    @include if-device(phone) {
      width: 100%;
    }
    @include if-device(tablet) {
      width: 100%;
    }
    @include if-device(largephone) {
      width: 100%;
    }

    &__company {
      height: 300px;
      width: 300px;

      @include if-device(phone) {
        height: 100%;
        width: 100%;
      }

      & > img {
        height: 100%;
        width: 100%;
      }
    }
  }
}
