@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.astrologerDetails {
  padding: 80px;
  background-color: #c9dae4;

  @include if-device(desktop) {
    padding: 50px;
  }
  @include if-device(tablet) {
    padding: 50px;
  }

  @include if-device(largephone) {
    padding: 50px;
  }

  @include if-device(phone) {
    padding: 50px 20px;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 70px;
    @include if-device(tablet) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(largephone) {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    @include if-device(phone) {
      flex-wrap: wrap;
      row-gap: 20px;
    }

    &__btn {
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 20px;
      position: relative;
      @include if-device(phone) {
        flex-wrap: wrap;
        row-gap: 10px;
      }
      &__dropDown {
        position: absolute;
        top: 48px;
        left: 34.5%;
      }
    }
  }

  &__bottom {
    display: flex;
    column-gap: 20px;
    width: 100%;

    @include if-device(tablet) {
      flex-wrap: wrap;
      row-gap: 50px;
    }

    @include if-device(largephone) {
      flex-wrap: wrap;
      row-gap: 50px;
    }

    @include if-device(phone) {
      flex-wrap: wrap;
      row-gap: 20px;
    }

    &__det {
      width: 25%;
      @include if-device(desktop) {
        width: 30%;
      }

      @include if-device(tablet) {
        width: 100%;
      }

      @include if-device(largephone) {
        width: 100%;
      }

      @include if-device(phone) {
        width: 100%;
      }
    }
    &__graph {
      width: 70%;
      border: 2px solid #8e8787;
      border-radius: 10px;
      background-color: #fff;
      padding: 30px;
      @include if-device(desktop) {
        height: 100%;
      }
      @include if-device(tablet) {
        height: 100%;
        width: 100%;
      }

      @include if-device(largephone) {
        height: 100%;
        width: 100%;
        padding: 10px;
      }

      @include if-device(phone) {
        height: 100%;
        width: 100%;
        padding: 10px;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        margin-bottom: 30px;
        border-bottom: 1px solid #ede9e9;
        @include if-device(phone) {
          flex-wrap: wrap;
          row-gap: 10px;
        }

        & > h4 {
          color: $eight_color;
          font-size: 22px;
          @include if-device(largephone) {
            font-size: 18px;
          }
          @include if-device(phone) {
            font-size: 18px;
          }
        }

        &__right {
          display: flex;
          align-items: center;
          column-gap: 30px;
        }
      }
    }
  }
}
.booking {
  flex: 1;
}
.details {
  display: flex;
  align-items: center;
  justify-content: center;
  & > p {
    font-size: 40px;
    font-weight: 700;
    color: #34aadc;
    margin-top: 23%;
    @include if-device(largephone) {
      font-size: 20px;
    }
    @include if-device(phone) {
      font-size: 20px;
    }
  }
}

.loader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
