@import "../../../../public/styles/breakpoints";

.item {
  display: flex;
  align-items: center;
  column-gap: 10px;

  &__circle {
    width: 25px;
    height: 25px;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    @include if-device(largephone) {
      width: 20px;
      height: 20px;
    }

    @include if-device(phone) {
      width: 20px;
      height: 20px;
    }
  }

  &__tag {
    font-size: 20px;
    color: #410d2e;

    @include if-device(largephone) {
      font-size: 16px;
    }

    @include if-device(phone) {
      font-size: 16px;
    }
  }
}
