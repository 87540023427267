@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";
.historyArea {
  width: 100%;
  border: 2px solid #03b2cb;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px 2px rgba(150, 143, 143, 0.5);
  background-color: #fff;
  overflow-y: scroll;
  height: 500px;
  border-top-right-radius: 0%;
  border-bottom-right-radius: 0%;
  &::-webkit-scrollbar {
    width: 10px;
    @include if-device(phone) {
      width: 5px;
    }
    @include if-device(largephone) {
      width: 5px;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $eight-color;
  }
  @include if-device(phone) {
    padding: 10px;
  }
  @include if-device(largephone) {
    padding: 10px;
  }
  & > h1 {
    text-align: center;
    color: #155651;
    margin-bottom: 30px;
  }
  &__imageArea {
    width: 100%;
    height: 250px;
    overflow: hidden;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
