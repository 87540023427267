@import "../../../public/styles/breakpoints";

.contactUs {
  margin: 80px 150px;
  padding: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  @include if-device(desktop) {
    margin: 80px 50px;
  }

  @include if-device(tablet) {
    margin: 50px;
  }
  @include if-device(largephone) {
    margin: 50px;
  }
  @include if-device(phone) {
    margin: 50px 20px;
    padding: 20px;
  }

  &__top {
    column-gap: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include if-device(tablet) {
      flex-wrap: wrap;
      row-gap: 50px;
      order: 2;
    }
    @include if-device(largephone) {
      flex-wrap: wrap;
      row-gap: 50px;
      order: 2;
    }
    @include if-device(phone) {
      flex-wrap: wrap;
      row-gap: 50px;
      order: 2;
    }
  }
}
