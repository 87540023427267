@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.pandit {
  height: 380px;
  width: 100%;

  & > img {
    width: 100%;
    height: 100%;
  }
}

.container {
  padding: 40px 150px;

  @include if-device(desktop) {
    padding: 40px 50px 80px 50px;
  }

  @include if-device(tablet) {
    padding: 50px;
  }
  @include if-device(largephone) {
    padding: 50px;
  }
  @include if-device(phone) {
    padding: 20px;
  }
  &__panditList {
    margin-bottom: 40px;
  }
  &__poojaList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @include if-device(desktop) {
      gap: 20px;
    }

    @include if-device(tablet) {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
    @include if-device(largephone) {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
    @include if-device(phone) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
    }
  }
  &__notFoundMessage {
    font-size: 35px;
    text-align: center;
    color: red;
    margin: 80px 0 0 0;
  }
}
.emptyMsg {
  width: 100%;
  height: 200px;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
