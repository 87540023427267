@import "../../../../public/styles/variables";
@import "../../../../public/styles/breakpoints";
.testimonialUser {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 5px 10px;
  position: absolute;
  bottom: 10px;
  left: 0;
  justify-content: space-between;
  width: -webkit-fill-available;
 
  @include if-device(phone) {
    column-gap: 0;
    padding:2px 10px;
  }
  &__profile {
    height: 80px;
    width: 80px;
    border: 1px solid gray;
    border-radius: 50%;
    display: none;
    & > img {
      width: 100%;
      height: 100%;
    }
  }

  &__det {
    &__name {
      font-size: 20px;
      // margin-bottom: 10px;
      color: $eight-color;
    }

    &__designation {
      font-size: 15px;
      text-transform: uppercase;
    }
  }
  // &__starArea {

  // }
}
