@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.modalLayout {
  width: 100%;
  margin: 0 auto;
  position: relative;
  border-radius: 20px;
  z-index: 0;
  background-color: #fff;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $eight-color;
  }

  @include if-device(tablet) {
    height: 100%;
    border-radius: 10px 0 0 10px;
    // overflow: auto;
  }
  @include if-device(largephone) {
    height: 100%;
    border-radius: 10px 0 0 10px;
  }
  @include if-device(phone) {
    height: 100%;
    border-radius: 10px 0 0 10px;
  }
  &__proceedBtn {
    position: fixed !important;
    right: 15px;
    z-index: 12;
    top: -5px;
    width: 200px;
  }
}
