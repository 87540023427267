.loading {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 50px;
  }
}
