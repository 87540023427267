label {
  width: 100%;
  font-size: 16px;
  color: #68574e;
}

.input {
  width: 100%;
  height: 45px;
  border: 1px solid #fff;
  margin: 5px 0;
  padding: 0 8px;
  font-size: 16px;
  position: relative;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    font-size: 16px;
  }

  &:focus {
    outline: none;
    border: 1px solid #fff;
  }
}
